import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  pointerEvt,
  foundation,
  Header3,
  Tag,
  Subtitle3,
  Body2,
  Subtitle4,
} from "ms-ui";
import styled from "styled-components";
import { Business } from "./Business";
import { MSHUTTLE_ASSETS } from "src/constants/addr";

interface IMainContact {
  onB2BHandler: () => void;
  onDrQuestionHandler: () => void;
}

export const MainContact = observer(
  ({ onB2BHandler, onDrQuestionHandler }: IMainContact) => {
    return (
      <>
        <StDiv1>
          <StDiv2 onClick={onB2BHandler} data-id={"main_B2B"}>
            <Business />
            <StDiv3>
              <StDiv4>
                <StDiv5>모두의셔틀 비즈니스</StDiv5>
                <Tag
                  color={"black"}
                  radius={"5"}
                  light={false}
                  msg={"상담문의"}
                  width={"fit-content"}
                />
              </StDiv4>
              <StDiv6>기업 및 단체 멤버 전용 서비스를 알아보세요!</StDiv6>
            </StDiv3>
          </StDiv2>
          <StDiv7 onClick={onDrQuestionHandler} data-id={"main_drQuestion"}>
            <StDiv8>
              <StDiv9>기사님, 모두의셔틀과 함께해요</StDiv9>
              <StDiv10>기사님의 안정적인 수익을 올려드립니다.</StDiv10>
            </StDiv8>
            <StDiv11>
              <StDiv12>
                파트너
                <br />
                문의하기
              </StDiv12>
            </StDiv11>
          </StDiv7>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[8].value};
  background: ${contents.background.value};
`;

const StDiv2 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[12].value};
  background: ${foundation.normal.primary.value};
  cursor: pointer;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv4 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Header3};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Subtitle3};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv7 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[16].value};
  justify-content: space-between;
  align-items: center;
  background: url(${MSHUTTLE_ASSETS}/images/main/bg_driver_mobile.jpg) lightgray
    50% / cover no-repeat;
  cursor: pointer;
  width: 100%;
`;

const StDiv8 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv9 = styled.div`
  ${Header3};
  align-self: stretch;
  color: ${contents.secondary.value};
`;

const StDiv10 = styled.div`
  ${Body2};
  align-self: stretch;
  color: ${contents.secondary.value};
`;

const StDiv11 = styled.div`
  display: flex;
  padding: 12px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: ${foundation.normal.secondary.value};
  width: fit-content;
`;

const StDiv12 = styled.div`
  ${Subtitle4};
  color: ${contents.secondary.value};
  text-align: center;
  width: fit-content;
`;
