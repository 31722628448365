export const Mshuttle = () => {
  return (
    <svg
      width="100%"
      height="79"
      viewBox="0 0 328 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0001 60.4849H17.5803V78.5646H35.0001V60.4849Z"
        fill="#73D1F7"
      />
      <path d="M17.5805 60.4849H0V78.5646H17.5805V60.4849Z" fill="#52B1DB" />
      <path
        d="M11.6214 47.8218H8.92626L0 60.4848H2.70755H17.5805L26.2595 47.8218H11.6214Z"
        fill="#52B1DB"
      />
      <path
        d="M27.4214 49.5092L26.2593 47.8218L17.5803 60.4848H19.6944H34.9259L34.9383 60.4564L27.4214 49.5092Z"
        fill="#97E7FF"
      />
      <path
        d="M123.074 32.0724V60.8986C123.074 64.572 125.706 67.5444 128.96 67.5444H256.114C259.567 67.5444 262.286 64.1935 261.976 60.3098L260.821 45.6724C260.747 44.761 260.511 43.8777 260.126 43.0786L258.101 38.9004L248.278 18.5567L247.881 17.7294L246.949 15.7946C245.919 13.6635 243.932 12.3315 241.783 12.3315H131.233C128.414 12.3315 125.992 14.5888 125.458 17.7014L123.185 30.7966C123.111 31.2172 123.074 31.6518 123.074 32.0724Z"
        fill="#6078EA"
      />
      <path
        d="M139.988 67.5449C140.062 60.2402 145.328 54.3516 151.81 54.3516C158.293 54.3516 163.558 60.2402 163.633 67.5449H139.988Z"
        fill="#111113"
      />
      <path
        d="M151.81 78.5646C157.126 78.5646 161.435 73.6998 161.435 67.6987C161.435 61.6976 157.126 56.8328 151.81 56.8328C146.495 56.8328 142.186 61.6976 142.186 67.6987C142.186 73.6998 146.495 78.5646 151.81 78.5646Z"
        fill="#18151C"
      />
      <path
        d="M146.085 67.6987C146.085 71.274 148.656 74.1763 151.822 74.1763C154.989 74.1763 157.56 71.274 157.56 67.6987C157.56 64.1235 154.989 61.2212 151.822 61.2212C148.643 61.2352 146.085 64.1235 146.085 67.6987Z"
        fill="#BBBBBD"
      />
      <path
        d="M220.274 67.5449C220.349 60.2402 225.614 54.3516 232.097 54.3516C238.579 54.3516 243.845 60.2402 243.919 67.5449H220.274Z"
        fill="#111113"
      />
      <path
        d="M232.097 78.5646C237.412 78.5646 241.721 73.6998 241.721 67.6987C241.721 61.6976 237.412 56.8328 232.097 56.8328C226.781 56.8328 222.472 61.6976 222.472 67.6987C222.472 73.6998 226.781 78.5646 232.097 78.5646Z"
        fill="#18151C"
      />
      <path
        d="M232.097 74.1763C235.265 74.1763 237.834 71.2762 237.834 67.6987C237.834 64.1213 235.265 61.2212 232.097 61.2212C228.928 61.2212 226.359 64.1213 226.359 67.6987C226.359 71.2762 228.928 74.1763 232.097 74.1763Z"
        fill="#BBBBBD"
      />
      <path
        d="M123.93 61.9927H126.24C126.638 61.9927 126.948 61.6282 126.948 61.1936V53.6646C126.948 53.216 126.625 52.8655 126.24 52.8655H123.098V61.0674C123.098 61.5721 123.471 61.9927 123.93 61.9927Z"
        fill="#E6E5E5"
      />
      <path
        d="M234.357 19.8048V36.8257C234.357 37.9754 235.177 38.9008 236.195 38.9008H258.114L248.291 18.5569L247.893 17.7297H236.207C235.177 17.7297 234.357 18.6551 234.357 19.8048Z"
        fill="#0C0D10"
      />
      <path
        d="M129.32 20.8423V36.8257C129.32 37.9754 130.152 38.9148 131.17 38.9148H228.632C230.147 38.9148 231.389 37.5268 231.389 35.8022V19.8188C231.389 18.6691 230.557 17.7297 229.538 17.7297H132.077C130.549 17.7297 129.32 19.1178 129.32 20.8423Z"
        fill="#0C0D10"
      />
      <path
        d="M218.436 61.4314H166.986"
        stroke="#D5F0FB"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M256.301 61.6421H246.192"
        stroke="#D5F0FB"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M137.181 61.2495H130.351"
        stroke="#D5F0FB"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M163.769 42.4756H160.876V43.6392H163.769V42.4756Z"
        fill="white"
      />
      <path
        d="M151.177 44.5789C150.382 44.5789 149.736 45.3079 149.736 46.2052C149.736 47.1025 150.382 47.8316 151.177 47.8316C151.972 47.8316 152.618 47.1025 152.618 46.2052C152.618 45.3079 151.972 44.5789 151.177 44.5789ZM151.177 46.6679C150.953 46.6679 150.767 46.4576 150.767 46.2052C150.767 45.9529 150.953 45.7426 151.177 45.7426C151.401 45.7426 151.587 45.9529 151.587 46.2052C151.587 46.4576 151.401 46.6679 151.177 46.6679Z"
        fill="#73D1F7"
      />
      <path
        d="M145.191 47.8316V44.5647H142.297V49.9207H145.191V48.7709H143.341V47.8456H145.191V47.8316ZM144.16 46.6678H143.341V45.7424H144.16V46.6678Z"
        fill="white"
      />
      <path
        d="M163.757 47.8316V46.6679H161.906V45.7426H163.757V44.5789H160.876V47.8316H163.757Z"
        fill="white"
      />
      <path
        d="M163.757 52.0234V50.8598H162.627L164.49 48.7708H160.876V49.9344H161.907H161.994L160.143 52.0234H163.757Z"
        fill="white"
      />
      <path d="M152.63 48.7708H149.736V49.9344H152.63V48.7708Z" fill="white" />
      <path d="M154.481 44.5789H153.45V47.8456H154.481V44.5789Z" fill="white" />
      <path
        d="M146.023 52.0234H147.054V49.9344H148.904V48.7708H146.023V52.0234Z"
        fill="white"
      />
      <path
        d="M146.023 47.8316H148.904V46.6679H147.054V45.7426H148.904V44.5789H146.023V47.8316Z"
        fill="white"
      />
      <path
        d="M157.672 44.5789V45.7425H159.013V46.6679H157.883L157.262 45.9669V44.5789H156.231V45.9669L155.089 47.2567L155.821 48.0839L156.74 47.0324L157.672 48.0839L157.883 47.8316H159.013V49.9346H160.044V45.7425V44.5789H157.672Z"
        fill="white"
      />
      <path
        d="M135.505 45.3642H130.761V50.72H129.73V44.2004H135.505V45.3642Z"
        fill="#73D1F7"
      />
      <path
        d="M135.505 50.7201H136.635C136.846 51.337 137.367 51.7717 137.976 51.7717C138.771 51.7717 139.416 51.0426 139.416 50.1452C139.416 49.2479 138.771 48.5188 137.976 48.5188C137.367 48.5188 136.833 48.9534 136.635 49.5704H135.505V50.7201ZM137.566 50.1452C137.566 49.8929 137.752 49.6825 137.976 49.6825C138.199 49.6825 138.386 49.8929 138.386 50.1452C138.386 50.3976 138.199 50.6079 137.976 50.6079C137.752 50.6079 137.566 50.3976 137.566 50.1452Z"
        fill="white"
      />
      <path
        d="M131.581 50.145C131.581 51.0423 132.227 51.7714 133.022 51.7714C133.63 51.7714 134.164 51.3367 134.363 50.7198H135.493V49.5562H134.375C134.164 48.9393 133.643 48.5046 133.034 48.5046C132.227 48.5046 131.581 49.2477 131.581 50.145ZM132.612 50.145C132.612 49.8926 132.798 49.6823 133.022 49.6823C133.245 49.6823 133.432 49.8926 133.432 50.145C133.432 50.3974 133.245 50.6077 133.022 50.6077C132.798 50.6077 132.612 50.3974 132.612 50.145Z"
        fill="#73D1F7"
      />
      <path
        d="M141.279 44.2004V50.72H140.248V45.3642H135.505V44.2004H141.279Z"
        fill="white"
      />
      <path
        d="M130.351 12.8787V10.7616C130.351 9.61197 131.009 8.67261 131.829 8.67261H155.61C155.921 8.67261 156.219 8.81281 156.48 9.0792L158.914 12.8787H130.351Z"
        fill="#D5F0FB"
      />
      <path d="M97.2042 50.2866H119.902" stroke="#4FD3FC" />
      <path d="M108.549 42.8174H119.902" stroke="#4FD3FC" />
      <path
        d="M102.618 28.7488H112.276V26.1796H106.163L111.988 17.464V15.5127H102.905V18.0819H108.434L102.618 26.7975V28.7488Z"
        fill="#FFD800"
      />
      <path
        d="M79.0001 21.1822H94.4546V17.0737H84.6818L93.9947 3.12204V0H79.46V4.10852H88.313L79.0001 18.0601V21.1822Z"
        fill="#FFD800"
      />
      <path
        d="M326.136 40.4773H307.773V78.5648H326.136V40.4773Z"
        fill="#73D1F7"
      />
      <path d="M307.773 40.4773H294V78.5648H307.773V40.4773Z" fill="#52B1DB" />
      <path
        d="M322.203 37.4944H307.773V40.477H322.203V37.4944Z"
        fill="#97E7FF"
      />
      <path
        d="M307.773 37.4944H297.934V40.477H307.773V37.4944Z"
        fill="#6DC4E2"
      />
      <path
        d="M313.021 41.9741H309.087V43.4705H313.021V41.9741Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 41.9741H314.983V43.4705H318.917V41.9741Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 41.9741H320.888V43.4705H324.822V41.9741Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 44.967H309.087V46.4634H313.021V44.967Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 44.967H314.983V46.4634H318.917V44.967Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 44.967H320.888V46.4634H324.822V44.967Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 47.9487H309.087V49.4454H313.021V47.9487Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 47.9487H314.983V49.4454H318.917V47.9487Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 47.9487H320.888V49.4454H324.822V47.9487Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 50.9319H309.087V52.4283H313.021V50.9319Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 50.9319H314.983V52.4283H318.917V50.9319Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 50.9319H320.888V52.4283H324.822V50.9319Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 53.9248H309.087V55.4213H313.021V53.9248Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 53.9248H314.983V55.4213H318.917V53.9248Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 53.9248H320.888V55.4213H324.822V53.9248Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 56.9072H309.087V58.4037H313.021V56.9072Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 56.9072H314.983V58.4037H318.917V56.9072Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 56.9072H320.888V58.4037H324.822V56.9072Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 59.9001H309.087V61.3965H313.021V59.9001Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 59.9001H314.983V61.3965H318.917V59.9001Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 59.9001H320.888V61.3965H324.822V59.9001Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 62.8826H309.087V64.379H313.021V62.8826Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 62.8826H314.983V64.379H318.917V62.8826Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 62.8826H320.888V64.379H324.822V62.8826Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 65.8655H309.087V67.3618H313.021V65.8655Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 65.8655H314.983V67.3618H318.917V65.8655Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 65.8655H320.888V67.3618H324.822V65.8655Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 68.8584H309.087V70.3549H313.021V68.8584Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 68.8584H314.983V70.3549H318.917V68.8584Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 68.8584H320.888V70.3549H324.822V68.8584Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 71.8408H309.087V73.3373H313.021V71.8408Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 71.8408H314.983V73.3373H318.917V71.8408Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 71.8408H320.888V73.3373H324.822V71.8408Z"
        fill="#CCEDFF"
      />
      <path
        d="M313.021 74.8335H309.087V76.33H313.021V74.8335Z"
        fill="#CCEDFF"
      />
      <path
        d="M318.917 74.8335H314.983V76.33H318.917V74.8335Z"
        fill="#CCEDFF"
      />
      <path
        d="M324.822 74.8335H320.888V76.33H324.822V74.8335Z"
        fill="#CCEDFF"
      />
    </svg>
  );
};
