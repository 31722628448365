/**
 * 제작관련 문구
 */
export const makeMsg = Object.seal({
  fail: {
    post: "알림받기 요청에 실패하였습니다. 잠시 후에 시도해주세요.",
    insert: "알림받기 요청에 실패했습니다",
    update: "알림받기 신청정보 수정에 실패했습니다.",
  },
  success: {
    update: "알림받기 신청정보가 수정되었습니다.",
  },
  confirm: {
    insert: "출근길 알림받기를 요청하시겠습니까?",
    update: "알림받기 신청정보를 수정하시겠습니까?",
  },
});

/**
 * 탑승 신청관련 문구
 */
export const boardMsg = Object.seal({
  insert: {
    success: `탑승신청이 완료되었습니다.   신청내역확인 및 수정은 [더 보기 - 신청내역]에서 가능합니다. \n 빠르게 운행될 수 있도록 공유는 필수! 주변 친구, 동료, 이웃, 지인들에게 소문내주세요 :)`,
    fail: "탑승 신청에 실패하였습니다.",
  },
  update: {
    success: "탑승신청 정보 수정이 완료되었습니다.",
    fail: "탑승신청 정보수정을 완료하지 못했습니다. 잠시 후 시도해주세요.",
  },

  complete: {
    b: {
      a: (
        <>
          신청해주셔서 감사합니다. <br />
          회사 앞까지 편하게 모셔드릴게요!
        </>
      ),
      e: (
        <>
          현재 잔여좌석이 없어 탑승대기자로 <br />
          신청이 완료되었습니다. 모두의셔틀 운영팀에서 <br />
          대기순번 안내를 드리겠습니다.
        </>
      ),
    },
    c: (
      <>
        신청해주신 출근길이 빨리 운행될 수 있도록 <br />
        주변에 많은 공유 부탁드려요!
      </>
    ),
    e: <></>,
  },
  seatError:
    "선택하신 좌석은 이미 다른 고객이 예약 중인 좌석입니다. 다른 좌석을 선택해주세요.",
  confirm: {
    insert: "탑승 신청하시겠습니까?",
    update: "수정하시겠습니까?",
  },
  cancel: {
    confirm: " 출근길의 탑승 신청을  \n 취소하시겠습니까?",
  },
});

/**
 * 더 보기 내 정보 문구
 *
 */

export const myInfoMsg = {
  pw: {
    success: "비밀번호가 변경되었습니다.",
    fail: "기존 비밀번호가 일치하지 않습니다.",
  },
  nm: {
    success: "이름이 변경되었습니다.",
    fail: "이름 변경에 실패하였습니다.",
  },
  nick: {
    success: "닉네임이 변경되었습니다.",
    fail: "닉네임 변경에 실패하였습니다.",
  },
  phone: {
    success: "휴대폰 번호가 변경되었습니다.",
    fail: "인증번호가 일치하지 않습니다.",
  },

  orgEmail: {
    success: "이메일이 변경되었습니다.",
    fail: "인증번호가 일치하지 않습니다.",
  },
} as const;

export const commonMsg = {
  fail: "잠시 후 시도해주세요.",
} as const;

export const emailMsg = {
  pay: {
    success: (
      <>
        이메일이 발송되었습니다. <br /> 메일함을 확인해주세요.
      </>
    ),
    fail: "이메일이 발송에 실패하였습니다.",
    notPartner: "제휴된 기업의 이메일이 아닙니다.",
  },
} as const;

export const CouponMsg = {
  success: "등록되었습니다",
  fail: "잘못된 쿠폰번호입니다.",
  duplicate: "이미 등록된 쿠폰번호입니다.",
  confirm: {
    insert: "쿠폰을 등록하시겠습니까?",
  },
} as const;

export const BusStopMsg = {
  alreadyApply: "이미 신청한 출근길 입니다.",
  ApplyOther: "이미 신청한 다른 출근길이 \n 존재합니다.",
  noDriver: "해당 출근길은 \n 신청 할 수 없습니다.",
} as const;

export const ErrorUrl = {
  error: "유효하지 않은 URL주소입니다.",
} as const;

/**
 * 외부 라이브러리 오류 문구
 */
export const thrdPtMsg = {
  kakaoKeyword: (
    <>
      현재 주소 검색이 어렵습니다. <br />
      사용 환경에 따라 검색이 제한될 수 있으니, 휴대폰을 사용하여 검색해 주세요.{" "}
      <br />
      (*사내 WIFI 연결 해제 및 애드블럭 해제 필수)
    </>
  ),
} as const;

/**
 * 환불계좌 입력폼 문구 (무통장 결제)
 */
export const refundAc = {
  confirm: "계좌를 인증하시겠습니까?",
  success: "계좌 인증에 성공하였습니다.",
  notMatch: "계좌주명이 일치하지 않습니다. \n 다시 확인하여 입력해 주세요.",
  fail: `계좌 인증이 정상적으로 \n 처리되지 않았습니다. 계좌 정보를 다시 \n 확인하여 입력해주세요.`,
} as const;

/**
 * 기간선택관련 문구
 */
export const periodMsg = {
  notInclude: (
    <>
      <strong>탑승 시작일을 다시 선택해 주세요.</strong> <br />
      무료 탑승은 신청일 기준 2일 뒤부터 <br />
      7일 이내에만 선택 가능합니다.
    </>
  ),
} as const;
