import * as React from "react";
import { getLS, setLS } from "../../../../../func/storage";
import { CloseToolTip } from "./CloseToolTop";
import { MediaQuery, spacing, useIntersectionObserver } from "ms-ui";
import styled from "styled-components";

export const MainToolTip = () => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const sKEY = "MAIN_SEARCH_TOOTIP_CLOSED";

  const { ref } = useIntersectionObserver({
    onIntersect: (entry) => {
      if (getData() === false) {
        setShowTooltip(true);
      }
    },
    // notIntersect: (entry) => {
    //   console.log("notIntersect", entry);
    // },
    options: { root: null, rootMargin: "-67px", threshold: 0 },
  });

  const getData = () => {
    const data = getLS(sKEY);
    return data !== null;
  };

  const close = () => {
    setLS(sKEY, true);
    setShowTooltip(false);
  };

  return (
    <>
      <div ref={ref} />
      {showTooltip === true ? (
        <StFixed>
          <CloseToolTip close={close}>
            <div>
              <div className={`title`}>아직 출근길 안 찾아보셨나요?</div>
              <div>
                집, 회사, 도착시간을 알려주시면 <br />
                최적의 출근길로 모셔드려요. <br />
                내가 원하는 탑승/하차지 추가도 가능!
              </div>
            </div>
          </CloseToolTip>
        </StFixed>
      ) : null}
    </>
  );
};

const StFixed = styled.div`
  ${MediaQuery}
  width: 100%;
  padding: 0 ${spacing[16].value};
  position: fixed;

  bottom: calc(constant(safe-area-inset-bottom) + 75px);
  bottom: calc(env(safe-area-inset-bottom) + 75px);

  z-index: 1070;
`;
