export const Covid19 = () => {
  return (
    <svg
      width="68"
      height="63"
      viewBox="0 0 68 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1477_4714)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.3115 35.3396C41.1313 32.6701 43.3417 29.4581 43.3417 22.1636V17.7975C43.3417 6.62037 35.4868 0 25.869 0C16.2488 0 8.39398 6.62037 8.39398 17.7975V22.1636C8.39398 29.4593 10.6031 32.6701 13.4277 35.3396C7.67709 36.7385 0 41.2423 0 49.0828V60.2387H51.7392V49.0828C51.7392 41.2423 44.0586 36.7385 38.3115 35.3396ZM24.0643 33.0066C21.0905 33.0066 18.4078 31.6983 16.59 29.6323C15.0774 27.9157 14.159 25.6744 14.159 23.2354V19.3258C14.159 19.0811 14.1721 18.8446 14.2043 18.6105C14.1768 18.3728 14.1625 18.1293 14.1625 17.8822V15.302C14.1625 13.9996 14.9904 13.9008 16.819 13.1925C18.0321 12.7972 19.5148 12.5042 21.1347 12.316C22.6364 12.1407 24.2527 12.0524 25.869 12.0524C27.4853 12.0524 29.1016 12.1407 30.6058 12.316C32.2232 12.5042 33.7095 12.7972 34.9226 13.1925C35.0228 13.2349 35.1206 13.2678 35.2149 13.3055C36.8383 13.9137 37.5803 14.0714 37.5803 15.302V17.8834C37.5803 18.141 37.5672 18.3881 37.5385 18.6316C37.566 18.8587 37.5803 19.0893 37.5803 19.327V23.2366C37.5803 25.4803 36.8037 27.5486 35.5047 29.2016C33.6904 31.5135 30.8515 33.0077 27.675 33.0077L24.0643 33.0066ZM35.166 52.0006C32.4594 52.0006 30.2646 49.8357 30.2646 47.1662C30.2646 44.4966 32.4594 42.3318 35.166 42.3318C37.8725 42.3318 40.0673 44.4966 40.0673 47.1662C40.0673 49.8357 37.8725 52.0006 35.166 52.0006Z"
          fill="#FFD800"
        />
        <path
          d="M25.869 39.7988C25.857 39.7988 25.8463 39.7988 25.8344 39.7976C24.2897 39.7399 20.5108 39.3882 18.7788 37.3622C18.4507 36.9786 18.5008 36.4045 18.8897 36.0809C19.2786 35.7574 19.8607 35.8068 20.1887 36.1903C21.044 37.1916 23.2185 37.8763 25.869 37.9798C28.5171 37.8763 30.6916 37.1916 31.5493 36.1903C31.8773 35.8068 32.4594 35.7585 32.8483 36.0833C33.2371 36.408 33.286 36.981 32.9568 37.3645C31.2212 39.3905 27.4459 39.7423 25.9024 39.7988C25.8928 39.7976 25.8809 39.7988 25.869 39.7988Z"
          fill="#E1C006"
        />
        <path
          d="M26.7912 33.0066V60.2223C26.7912 60.2281 26.7912 60.2328 26.79 60.2387H24.9494C24.9482 60.2328 24.9482 60.2281 24.9482 60.2223V33.0066C24.9482 32.5054 25.3598 32.0983 25.8691 32.0983C26.3796 32.0983 26.7912 32.5054 26.7912 33.0066Z"
          fill="#E6EDFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2043 18.6116C14.1733 18.8458 14.1589 19.0822 14.1589 19.3258V23.2354C14.1589 25.6779 15.0786 27.9145 16.5899 29.6323C18.4078 31.6971 21.0905 33.0066 24.0654 33.0066H25.8702H27.6738C30.8503 33.0066 33.6892 31.5124 35.5035 29.1993C36.8025 27.5463 37.5791 25.4779 37.5791 23.2354V19.3258C37.5791 19.0893 37.5647 18.8587 37.5373 18.6316C37.1651 21.9201 14.5669 21.913 14.2043 18.6116Z"
          fill="#98CBFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.3416 22.1636V17.8763H40.109V22.0577C40.109 28.5686 37.3679 32.0112 35.117 35.1784C34.8451 35.5596 34.1413 36.7762 35.8733 37.2468C41.6204 38.6457 48.5066 43.2248 48.5066 49.8216V60.2387H51.7392V49.0827C51.7392 41.2423 44.0585 36.7385 38.3114 35.3396C41.1325 32.6701 43.3416 29.4581 43.3416 22.1636Z"
          fill="#E1C006"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.3417 17.7975C43.3417 6.62037 35.4868 0 25.869 0C25.5553 0 25.2452 0.00705922 24.9386 0.0200011C33.4757 0.99535 40.1079 7.42042 40.1079 17.6869V17.8763H43.3405V17.7975H43.3417Z"
          fill="#E1C006"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.607 12.3171C29.1029 12.1418 27.4866 12.0524 25.8703 12.0524C24.254 12.0524 22.6377 12.1418 21.1371 12.3171C19.5173 12.5065 18.0334 12.7983 16.8215 13.1936C14.9928 13.9019 14.1638 14.0007 14.1638 15.3031V17.8762V17.8833C14.1638 18.1304 14.1781 18.3751 14.2056 18.6116C14.5682 21.9129 17.3785 24.0225 20.5204 23.9883C23.1256 23.9648 23.864 23.3601 24.6834 22.3529C24.99 21.9788 25.4325 21.7894 25.8715 21.7929C26.314 21.7894 26.7566 21.9788 27.0631 22.3529C27.8814 23.3601 28.6198 23.9648 31.2261 23.9883C34.3609 24.0225 37.1688 21.92 37.541 18.6316C37.5684 18.388 37.5827 18.1398 37.5827 17.8821V17.875V15.302C37.5827 14.0725 36.8408 13.9136 35.2174 13.3054C35.1231 13.2677 35.0253 13.2336 34.9251 13.1924C33.7108 12.7983 32.2233 12.5065 30.607 12.3171Z"
          fill="#FFE5DD"
        />
        <path
          d="M41.3889 60.2385V53.697C41.3889 53.1946 41.8016 52.7875 42.311 52.7875C42.8203 52.7875 43.233 53.1946 43.233 53.697V60.2385H41.3889Z"
          fill="#E1C006"
        />
        <path
          d="M7.99792 60.2385V53.697C7.99792 53.1946 8.41065 52.7875 8.91999 52.7875C9.42933 52.7875 9.84205 53.1946 9.84205 53.697V60.2385H7.99792Z"
          fill="#E1C006"
        />
        <path
          d="M35.2137 14.216C35.0276 14.216 34.8392 14.1607 34.6757 14.0442C34.263 13.7513 34.1688 13.183 34.4658 12.7759L35.8137 10.9276C36.1107 10.5205 36.6869 10.4276 37.1008 10.7205C37.5135 11.0135 37.6077 11.5817 37.3107 11.9888L35.9628 13.8372C35.7827 14.0842 35.5012 14.216 35.2137 14.216Z"
          fill="#E1C006"
        />
        <path
          d="M16.6531 14.009C16.3669 14.009 16.0841 13.8772 15.904 13.6313L14.5561 11.783C14.2591 11.3759 14.3533 10.8077 14.7661 10.5147C15.1788 10.2217 15.7549 10.3147 16.0531 10.7218L17.401 12.5701C17.6981 12.9772 17.6038 13.5455 17.1911 13.8384C17.0277 13.9537 16.8404 14.009 16.6531 14.009Z"
          fill="#E1C006"
        />
        <path
          d="M25.8691 12.9619C25.3597 12.9619 24.947 12.5548 24.947 12.0525V9.3076C24.947 8.80521 25.3597 8.39813 25.8691 8.39813C26.3784 8.39813 26.7911 8.80521 26.7911 9.3076V12.0525C26.7911 12.5548 26.3784 12.9619 25.8691 12.9619Z"
          fill="#E1C006"
        />
        <path
          d="M21.1347 13.2266C20.7028 13.2266 20.3176 12.9266 20.2317 12.4924L19.7164 9.90874C19.6186 9.41577 19.943 8.9381 20.4428 8.84162C20.9438 8.74515 21.4269 9.06516 21.5247 9.55813L22.04 12.1418C22.1378 12.6348 21.8134 13.1124 21.3136 13.2089C21.2539 13.2207 21.1943 13.2266 21.1347 13.2266Z"
          fill="#E1C006"
        />
        <path
          d="M30.6068 13.2266C30.5484 13.2266 30.4876 13.2207 30.4279 13.2089C29.9281 13.1124 29.6037 12.6336 29.7015 12.1418L30.2168 9.55813C30.3146 9.06516 30.8001 8.74515 31.2987 8.84162C31.7985 8.9381 32.1229 9.41695 32.0251 9.90874L31.5098 12.4924C31.4239 12.9254 31.0387 13.2266 30.6068 13.2266Z"
          fill="#E1C006"
        />
        <path
          d="M21.087 20.3353C20.5776 20.3353 20.1649 19.9283 20.1649 19.4259V19.0647C20.1649 18.5623 20.5776 18.1552 21.087 18.1552C21.5963 18.1552 22.009 18.5623 22.009 19.0647V19.4259C22.009 19.9283 21.5963 20.3353 21.087 20.3353Z"
          fill="#7E707D"
        />
        <path
          d="M22.436 17.2974C22.393 17.2974 22.3501 17.295 22.3072 17.288L19.3287 16.8762C18.8241 16.8068 18.4734 16.3467 18.5438 15.8491C18.6142 15.3514 19.0817 15.0055 19.5839 15.0749L22.5624 15.4867C23.067 15.5561 23.4177 16.0161 23.3473 16.5138C23.2829 16.9691 22.8881 17.2974 22.436 17.2974Z"
          fill="#7E707D"
        />
        <path
          d="M30.6308 20.3353C30.1215 20.3353 29.7087 19.9283 29.7087 19.4259V19.0647C29.7087 18.5623 30.1215 18.1552 30.6308 18.1552C31.1401 18.1552 31.5529 18.5623 31.5529 19.0647V19.4259C31.5529 19.9283 31.1401 20.3353 30.6308 20.3353Z"
          fill="#7E707D"
        />
        <path
          d="M29.2853 17.2974C28.8332 17.2974 28.4384 16.9692 28.374 16.515C28.3036 16.0174 28.6543 15.5585 29.1589 15.4879L32.1374 15.0761C32.6408 15.0067 33.1072 15.3526 33.1775 15.8503C33.2479 16.348 32.8972 16.8068 32.3926 16.8774L29.4141 17.2892C29.3712 17.2951 29.3282 17.2974 29.2853 17.2974Z"
          fill="#7E707D"
        />
        <path
          d="M43.3417 18.7846H37.5803C37.0709 18.7846 36.6582 18.3775 36.6582 17.8752C36.6582 17.3728 37.0709 16.9657 37.5803 16.9657H43.3417C43.851 16.9657 44.2637 17.3728 44.2637 17.8752C44.2637 18.3775 43.8498 18.7846 43.3417 18.7846Z"
          fill="#98CBFF"
        />
        <path
          d="M14.1625 18.7846H8.47748C7.96814 18.7846 7.55542 18.3775 7.55542 17.8752C7.55542 17.3728 7.96814 16.9657 8.47748 16.9657H14.1625C14.6719 16.9657 15.0846 17.3728 15.0846 17.8752C15.0846 18.3775 14.6719 18.7846 14.1625 18.7846Z"
          fill="#98CBFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.166 42.3317C32.4595 42.3317 30.2646 44.4965 30.2646 47.1661C30.2646 49.8356 32.4595 52.0004 35.166 52.0004C37.8725 52.0004 40.0674 49.8356 40.0674 47.1661C40.0674 44.4965 37.8725 42.3317 35.166 42.3317Z"
          fill="#E6EDFF"
        />
        <path
          d="M36.8455 46.2567H36.0881V45.5096C36.0881 45.0072 35.6754 44.6001 35.166 44.6001C34.6567 44.6001 34.244 45.0072 34.244 45.5096V46.2567H33.4865C32.9772 46.2567 32.5645 46.6637 32.5645 47.1661C32.5645 47.6685 32.9772 48.0756 33.4865 48.0756H34.244V48.8192C34.244 49.3215 34.6567 49.7286 35.166 49.7286C35.6754 49.7286 36.0881 49.3215 36.0881 48.8192V48.0756H36.8455C37.3549 48.0756 37.7676 47.6685 37.7676 47.1661C37.7676 46.6637 37.3537 46.2567 36.8455 46.2567Z"
          fill="#F5848B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5385 18.6316C37.566 18.3881 37.5803 18.1398 37.5803 17.8822V17.8751V15.302C37.5803 14.0725 36.8383 13.9137 35.2149 13.3054L34.9226 13.1925C34.8594 13.1748 34.801 13.1548 34.7413 13.1337C35.0443 13.3913 35.191 13.7243 35.191 14.2737V16.8468V16.8539C35.191 17.1115 35.1767 17.3586 35.1493 17.6033C34.7795 20.8918 31.9727 22.9942 28.838 22.9601C28.3394 22.9566 27.9111 22.9295 27.5354 22.8848C28.232 23.5684 29.1171 23.9672 31.2236 23.9872C34.3584 24.0225 37.1652 21.92 37.5385 18.6316Z"
          fill="#FFD6C6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5036 29.2006C36.8026 27.5476 37.5791 25.4792 37.5791 23.2367V19.3271C37.5791 19.0906 37.5648 18.86 37.5373 18.6329C37.3358 20.3954 36.4399 21.8178 35.1648 22.752V23.4567C35.1648 25.6969 34.3918 27.7687 33.0916 29.4218C31.6805 31.2148 29.6575 32.5172 27.3374 33.009H27.6714C30.8503 33.0067 33.6893 31.5125 35.5036 29.2006Z"
          fill="#80B5E3"
        />
        <path
          d="M27.0642 30.6111H24.6713C24.162 30.6111 23.7493 30.204 23.7493 29.7016C23.7493 29.1992 24.162 28.7922 24.6713 28.7922H27.0642C27.5735 28.7922 27.9862 29.1992 27.9862 29.7016C27.9862 30.204 27.5735 30.6111 27.0642 30.6111ZM27.0642 27.5733H24.6713C24.162 27.5733 23.7493 27.1662 23.7493 26.6638C23.7493 26.1614 24.162 25.7543 24.6713 25.7543H27.0642C27.5735 25.7543 27.9862 26.1614 27.9862 26.6638C27.9862 27.1662 27.5735 27.5733 27.0642 27.5733Z"
          fill="#80B5E3"
        />
        <path
          d="M20.4488 24.8978C18.4973 24.8978 16.6186 24.1542 15.2838 22.8518C13.9657 21.5647 13.2405 19.801 13.2405 17.8833V15.3031C13.2405 13.5489 14.4273 13.1101 15.8015 12.603C16.0102 12.5253 16.2381 12.4418 16.4826 12.3465C16.4981 12.3406 16.5136 12.3347 16.5303 12.33C21.4186 10.737 30.3231 10.737 35.2113 12.33C35.2268 12.3347 35.2435 12.3406 35.259 12.3465C35.5047 12.4418 35.7314 12.5253 35.9401 12.603C37.3143 13.1101 38.5011 13.5489 38.5011 15.3031V17.8833C38.5011 19.801 37.7759 21.5658 36.4578 22.8518C35.123 24.1542 33.2443 24.8978 31.2916 24.8978C31.2654 24.8978 31.2392 24.8978 31.2129 24.8978C28.4312 24.8719 27.3899 24.2119 26.3414 22.9224C26.2281 22.7836 26.0599 22.7024 25.8809 22.7024C25.8774 22.7024 25.8726 22.7024 25.869 22.7024C25.8654 22.7024 25.8607 22.7024 25.8571 22.7024C25.6806 22.7024 25.5136 22.7836 25.3979 22.9247C24.3505 24.213 23.3092 24.8731 20.5263 24.8978C20.5013 24.8978 20.475 24.8978 20.4488 24.8978ZM17.1327 14.0489C16.887 14.1442 16.6591 14.2278 16.448 14.3054C15.0834 14.809 15.0834 14.8466 15.0834 15.3031V17.8833C15.0834 19.3328 15.6011 20.6034 16.5804 21.56C17.5717 22.5282 18.9792 23.08 20.45 23.08C20.4703 23.08 20.4905 23.08 20.5096 23.08C22.9275 23.0577 23.3617 22.5235 23.9629 21.7847C24.4328 21.2105 25.1211 20.8846 25.8571 20.8846H25.8809C26.6193 20.8846 27.31 21.2117 27.7776 21.7823C28.3799 22.5235 28.8141 23.0577 31.232 23.08C31.2523 23.08 31.2726 23.08 31.2916 23.08C32.7624 23.08 34.17 22.5282 35.1612 21.56C36.1405 20.6046 36.6582 19.3328 36.6582 17.8833V15.3031C36.6582 14.8466 36.6582 14.8102 35.2936 14.3066C35.0825 14.229 34.8546 14.1442 34.6089 14.0501C30.1072 12.5912 21.6345 12.5912 17.1327 14.0489Z"
          fill="#E6EDFF"
        />
        <path
          d="M57.0782 29.2333H53.6858V23.3506C53.6858 19.5175 50.5343 16.4091 46.6481 16.4091C42.7618 16.4091 39.6104 19.5175 39.6104 23.3506V39.2339C39.6104 39.268 39.6127 39.3009 39.6127 39.335C39.6115 39.4021 39.6104 39.4692 39.6104 39.5362V52.6982C39.6104 58.3879 44.2863 62.9999 50.0548 62.9999H57.077C62.8456 62.9999 66.3287 58.3879 66.3287 52.6982V39.5362C66.3299 33.8465 62.8468 29.2333 57.0782 29.2333Z"
          fill="#F3DBD2"
        />
        <path
          d="M66.8071 36.0762C66.8071 32.5571 63.9144 29.704 60.3467 29.704H50.0656C46.4979 29.704 43.6052 32.5571 43.6052 36.0762C43.6052 37.2433 43.9249 38.3351 44.4796 39.2752C43.9249 40.2152 43.6052 41.3082 43.6052 42.4742C43.6052 43.7778 44.0024 44.9896 44.6835 45.9991C44.0024 47.0085 43.6052 48.2204 43.6052 49.524C43.6052 50.7582 43.9619 51.91 44.5786 52.8865C43.9631 53.863 43.6052 55.0137 43.6052 56.2491C43.6052 59.7681 46.4979 62.6212 50.0656 62.6212H60.3467C63.9144 62.6212 66.8071 59.7681 66.8071 56.2491C66.8071 55.0149 66.4504 53.863 65.8337 52.8865C66.4492 51.91 66.8071 50.7593 66.8071 49.524C66.8071 48.2204 66.4099 47.0085 65.7287 45.9991C66.4099 44.9896 66.8071 43.7778 66.8071 42.4742C66.8071 41.307 66.4874 40.2152 65.9327 39.2752C66.4886 38.3351 66.8071 37.2433 66.8071 36.0762Z"
          fill="#F3C9BF"
        />
        <path
          d="M67.9999 36.4291C67.9999 32.9101 65.1073 30.057 61.5395 30.057H51.2585C47.6907 30.057 44.7981 32.9101 44.7981 36.4291C44.7981 37.5963 45.1178 38.6881 45.6724 39.6281C45.1178 40.5682 44.7981 41.6612 44.7981 42.8271C44.7981 44.1307 45.1953 45.3426 45.8764 46.352C45.1953 47.3615 44.7981 48.5733 44.7981 49.8769C44.7981 51.1111 45.1548 52.263 45.7714 53.2395C45.1559 54.216 44.7981 55.3667 44.7981 56.602C44.7981 60.121 47.6907 62.9741 51.2585 62.9741H61.5395C65.1073 62.9741 67.9999 60.121 67.9999 56.602C67.9999 55.3678 67.6433 54.216 67.0266 53.2395C67.6421 52.263 67.9999 51.1123 67.9999 49.8769C67.9999 48.5733 67.6027 47.3615 66.9216 46.352C67.6027 45.3426 67.9999 44.1307 67.9999 42.8271C67.9999 41.66 67.6803 40.5682 67.1256 39.6281C67.6814 38.6881 67.9999 37.5963 67.9999 36.4291Z"
          fill="#F3DBD2"
        />
        <path
          d="M60.9621 40.3928H44.8588C44.4306 40.3928 44.0835 40.0505 44.0835 39.6281C44.0835 39.2057 44.4306 38.8633 44.8588 38.8633H60.9621C61.3903 38.8633 61.7375 39.2057 61.7375 39.6281C61.7375 40.0505 61.3903 40.3928 60.9621 40.3928Z"
          fill="#F3C9BF"
        />
        <path
          d="M60.9621 46.9813H44.8588C44.4306 46.9813 44.0835 46.639 44.0835 46.2166C44.0835 45.7942 44.4306 45.4518 44.8588 45.4518H60.9621C61.3903 45.4518 61.7375 45.7942 61.7375 46.2166C61.7375 46.639 61.3903 46.9813 60.9621 46.9813Z"
          fill="#F3C9BF"
        />
        <path
          d="M60.9621 54.0405H44.8588C44.4306 54.0405 44.0835 53.6982 44.0835 53.2758C44.0835 52.8534 44.4306 52.511 44.8588 52.511H60.9621C61.3903 52.511 61.7375 52.8534 61.7375 53.2758C61.7375 53.6982 61.3903 54.0405 60.9621 54.0405Z"
          fill="#F3C9BF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1477_4714">
          <rect width="67.9999" height="63" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
