/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ResGetPopup  } from './interface/res/get/Res.Get.Popup';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Popup {

/**
 * popupList 출력 조건
1. 시작일 start_day <= today  
2. 종료일 end_day >= today  
3. 배포 deploy_yn = 1  
4. 최신순으로 5개씩 출력

*/
get_popup_list(){
 return GET<ResGetPopup>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.common_resource}`,
   url: `/popup`,
 });
}
 
}

export const PopupRepository = new Popup();
export const { get_popup_list } = PopupRepository;
	