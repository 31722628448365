import React from "react";
import styled, { css } from "styled-components";
import { Cancel, borderRadius, colors, pointerEvt, spacing } from "ms-ui";
import { Body2, Subtitle2 } from "ms-ui";

interface styledCloseToolTipProps {
  width?: string;
  "data-id"?: string;
  direction?: "up" | "down";
  close: () => void;
  floating?: boolean;
}

export interface ICloseToolTip extends styledCloseToolTipProps {
  children: any;
}

export const CloseToolTip = (props: ICloseToolTip) => {
  const { width, direction, floating } = props;
  return (
    <div style={{ position: `relative` }}>
      <StyledCloseToolTipLayout
        $width={width}
        $floating={floating}
        $direction={direction}
      >
        <StyledCloseBtnCtn>
          <StyledCloseBtn
            onClick={(e: any) => props.close()}
            data-id={props["data-id"]}
          >
            <Cancel color={`${colors.gray["090"].value}`} />
          </StyledCloseBtn>
        </StyledCloseBtnCtn>
        <StyledCloseToolTipTxt>{props.children}</StyledCloseToolTipTxt>
      </StyledCloseToolTipLayout>
    </div>
  );
};

const StyledCloseBtnCtn = styled.div`
  position: relative;
  width: 100%;
  height: ${spacing[28].value};
`;

const StyledCloseBtn = styled.div<{ "data-id"?: string }>`
  position: absolute;
  padding-top: ${spacing[4].value};
  padding-right: ${spacing[12].value};
  top: 0;
  right: 0;
  cursor: pointer;

  ${(p) => pointerEvt(p["data-id"])};
`;

const StyledCloseToolTipLayout = styled.div<{
  $width?: string;
  $floating?: boolean;
  $direction?: "up" | "down";
}>`
  width: ${(p) => p.$width || "100%"};
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  border-radius: ${borderRadius[10].value};

  z-index: 12;
  will-change: transform;

  /* background-color */
  background-color: ${colors.mainblue[400].value};

  /* floating */
  ${(p) =>
    p.$floating !== false
      ? css`
          position: absolute;
        `
      : null}

  /* textarea spot */
  ${(p) =>
    p.$direction === "down"
      ? `top: calc( 100% + ${spacing[16].value} + ${spacing[8].value});`
      : `bottom: calc( 100% + ${spacing[16].value} + ${spacing[8].value});`}
  
  &:after {
    content: "";
    position: absolute;
    left: calc(100% - 36.5% - 16px);

    /* arrow spot */
    ${(p) =>
      p.$direction === "down"
        ? `top: calc(-1 * 32px);`
        : `bottom: calc(-1 * 32px);`}

    width: calc(32px - 1px);
    height: calc(32px - 1px);
    border: calc(32px / 2) solid rgba(0, 0, 0, 0);

    /* arrow direction */
    ${(p) =>
      p.$direction === "down"
        ? `border-bottom: calc(32px / 2) solid ${colors.mainblue[400].value};`
        : `border-top: calc(32px / 2) solid ${colors.mainblue[400].value};`}
  }
`;

const StyledCloseToolTipTxt = styled.div`
  padding: 0 ${spacing[16].value} ${spacing[24].value};
  color: ${colors.gray["000"].value};
  ${Body2}

  .title {
    ${Subtitle2}
  }
`;
