import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  Header1,
  foundation,
  Body1,
  Subtitle1,
  Body2,
  Subtitle4,
  Body3,
  Caption1,
  Subtitle2,
  Button,
  Header3,
  colors,
} from "ms-ui";
import styled from "styled-components";
import { Shuttle } from "./Shuttle";
import { Covid19 } from "./Covid19";
import { Bus } from "./Bus";

interface IServiceSection {
  onSearchHandler: () => void;
}

export const ServiceSection = observer(
  ({ onSearchHandler }: IServiceSection) => {
    return (
      <>
        <StDiv1>
          <StDiv2>
            <StDiv3>매일 아침,</StDiv3>
            <StDiv4>
              <StDiv5>상쾌하게 출근하는 방법!</StDiv5>
            </StDiv4>
          </StDiv2>
          <Bus />
          <StDiv6>
            <StDiv7>
              숨 막힐 정도로 꽉찬 지하철,
              <br />꽉 막힌 도로와 주차 경쟁,
              <br />
              비싼 주차비와 택시비...😱
            </StDiv7>
            <StDiv8>
              이제 모두의셔틀이 집에서 회사까지
              <br />
              편하게 모셔드릴게요!
            </StDiv8>
            <StDiv9>
              매일 아침 커피값으로 ☕️
              <br />
              꿀잠출근 해보세요 🤗
            </StDiv9>
          </StDiv6>
          <StDiv10>
            <StDiv11>
              <StDiv12>
                <StDiv13>야탑역 ▶︎ 여의도역 출근 비교</StDiv13>
              </StDiv12>
              <StDiv14>
                <StDiv15>
                  <StDiv16></StDiv16>
                  <StDiv17>
                    <StDiv18>환승여부</StDiv18>
                  </StDiv17>
                  <StDiv19>
                    <StDiv20>집 앞 탑승</StDiv20>
                  </StDiv19>
                  <StDiv21>
                    <StDiv22>회사 앞 하차</StDiv22>
                  </StDiv21>
                  <StDiv23>
                    <StDiv24>자면서 출근</StDiv24>
                  </StDiv23>
                  <StDiv25>
                    <StDiv26>아침 스트레스</StDiv26>
                  </StDiv25>
                </StDiv15>
                <StDiv27>
                  <StDiv28>
                    <StDiv29>대중교통</StDiv29>
                  </StDiv28>
                  <StDiv30>
                    <StDiv31>1회</StDiv31>
                  </StDiv30>
                  <StDiv32>
                    <StDiv33>X</StDiv33>
                  </StDiv32>
                  <StDiv34>
                    <StDiv35>X</StDiv35>
                  </StDiv34>
                  <StDiv36>
                    <StDiv37>X</StDiv37>
                  </StDiv36>
                  <StDiv38>
                    <StDiv39>꽉찬 지하철</StDiv39>
                  </StDiv38>
                </StDiv27>
                <StDiv40>
                  <StDiv41>
                    <StDiv42>모두의셔틀</StDiv42>
                  </StDiv41>
                  <StDiv43>
                    <StDiv44>없음</StDiv44>
                  </StDiv43>
                  <StDiv45>
                    <StDiv46>O</StDiv46>
                  </StDiv45>
                  <StDiv47>
                    <StDiv48>O</StDiv48>
                  </StDiv47>
                  <StDiv49>
                    <StDiv50>O</StDiv50>
                  </StDiv49>
                  <StDiv51>
                    <StDiv52>없음</StDiv52>
                  </StDiv51>
                </StDiv40>
                <StDiv53>
                  <StDiv54>
                    <StDiv55>택시,자가용</StDiv55>
                  </StDiv54>
                  <StDiv56>
                    <StDiv57>없음</StDiv57>
                  </StDiv56>
                  <StDiv58>
                    <StDiv59>O</StDiv59>
                  </StDiv58>
                  <StDiv60>
                    <StDiv61>△</StDiv61>
                  </StDiv60>
                  <StDiv62>
                    <StDiv63>△</StDiv63>
                  </StDiv62>
                  <StDiv64>
                    <StDiv65>꽉 막힌 도로</StDiv65>
                  </StDiv64>
                </StDiv53>
              </StDiv14>
            </StDiv11>
            <StDiv66>
              ※ 탑승시 집과 회사 근처에 정류장을 추가해 드립니다.
            </StDiv66>
          </StDiv10>
          <StDiv67>
            <StDiv68>
              <StDiv69>
                <StDiv70>우리집에서 회사까지</StDiv70>
              </StDiv69>
              <StDiv71>
                <StDiv72>꿀잠출근이 가능한지 알아볼까요?</StDiv72>
              </StDiv71>
            </StDiv68>
            <Button
              color={"positive"}
              size={"rg"}
              radius={"5"}
              outline={false}
              data-id={"main_midSearch"}
              width={"100%"}
              onClick={onSearchHandler}
            >
              내 출근길 찾아보기 👉
            </Button>
          </StDiv67>
          <StDiv73>
            <StDiv74>
              <StDiv75>
                <StDiv76>1시간 넘는 출근길,</StDiv76>
                <StDiv77>한번에 앉아서 출근하세요!</StDiv77>
              </StDiv75>
              <Shuttle />
            </StDiv74>
            <StDiv78>
              <StDiv79>
                집에서 회사까지 한 번에!
                <br />
                100% 앉아서 출근할 수 있습니다.
                <br />
                편안히 자면서 출근하세요!
                <br />
                <br />
                택시만큼 편하면서
                <br />
                합리적인 가격으로 모셔드립니다.
              </StDiv79>
            </StDiv78>
          </StDiv73>
          <StDiv80>
            <StDiv81>
              <StDiv82>
                <StDiv83>매일 방역은 필수!</StDiv83>
                <StDiv84>안전한 꿀잠 출근길!</StDiv84>
              </StDiv82>
              <Covid19 />
            </StDiv81>
            <StDiv85>
              <StDiv86>
                아침 출근길이 불안하다면
                <br />
                모두의셔틀로 안심 출근하세요!
                <br />
                <br />
                매일 방역하는 차량으로
                <br />
                안전하게 모셔드립니다.
              </StDiv86>
            </StDiv85>
          </StDiv80>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[32].value} 0px;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[24].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  width: 100%;
`;

const StDiv3 = styled.div`
  ${Header1};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv4 = styled.div`
  display: flex;
  width: 328px;
`;

const StDiv5 = styled.div`
  ${Header1};
  flex: 1 0 0;
  color: ${foundation.normal.primary.value};
`;

const StDiv6 = styled.div`
  display: flex;
  width: 328px;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[16].value};
`;

const StDiv7 = styled.div`
  ${Body1};
  color: ${contents.subtitle.value};
  text-align: center;
  width: fit-content;
`;

const StDiv8 = styled.div`
  ${Body1};
  color: ${contents.subtitle.value};
  text-align: center;
  width: fit-content;
`;

const StDiv9 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv10 = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const StDiv11 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};
  width: 100%;
`;

const StDiv12 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StDiv13 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.primary.value};
  text-align: center;
`;

const StDiv14 = styled.div`
  display: flex;
  width: 100%;
`;

const StDiv15 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv16 = styled.div`
  display: flex;
  height: 28px;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${contents.tertiary.value};
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.secondary.value};
  width: 100%;
`;

const StDiv17 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${foundation.light.secondary.value};
  width: 100%;
`;

const StDiv18 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv19 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${foundation.light.secondary.value};
  width: 100%;
`;

const StDiv20 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv21 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${foundation.light.secondary.value};
  width: 100%;
`;

const StDiv22 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv23 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${foundation.light.secondary.value};
  width: 100%;
`;

const StDiv24 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv25 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${foundation.light.secondary.value};
  width: 100%;
`;

const StDiv26 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv27 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv28 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${contents.tertiary.value};
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.tertiary.value};
  width: 100%;
`;

const StDiv29 = styled.div`
  ${Subtitle4};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv30 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv31 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv32 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv33 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv34 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv35 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv36 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv37 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv38 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv39 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv40 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv41 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${foundation.normal.primary.value};
  border-bottom: 1px solid ${foundation.normal.primary.value};
  background: ${foundation.normal.primary.value};
  width: 100%;
`;

const StDiv42 = styled.div`
  ${Subtitle4};
  color: ${contents.secondary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv43 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.secondary.value};
  width: 100%;
`;

const StDiv44 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv45 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.secondary.value};
  width: 100%;
`;

const StDiv46 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv47 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.secondary.value};
  width: 100%;
`;

const StDiv48 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv49 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.secondary.value};
  width: 100%;
`;

const StDiv50 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv51 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.secondary.value};
  width: 100%;
`;

const StDiv52 = styled.div`
  ${Subtitle4};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv53 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv54 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${contents.tertiary.value};
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.tertiary.value};
  width: 100%;
`;

const StDiv55 = styled.div`
  ${Subtitle4};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv56 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv57 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv58 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv59 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv60 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv61 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv62 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv63 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv64 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${contents.tertiary.value};
  background: ${contents.background.value};
  width: 100%;
`;

const StDiv65 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv66 = styled.div`
  ${Caption1};
  align-self: stretch;
  color: ${contents.accent.value};
`;

const StDiv67 = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  background: ${foundation.light.primary.value};
  width: 100%;
`;

const StDiv68 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StDiv69 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const StDiv70 = styled.div`
  ${Body1};
  color: var(--contents-primary, #1a202c);
  text-align: center;
  width: fit-content;
`;

const StDiv71 = styled.div`
  display: flex;
  width: 328px;
  justify-content: center;
  align-items: center;
`;

const StDiv72 = styled.div`
  ${Subtitle2};
  color: var(--contents-primary, #1a202c);
  text-align: center;
  width: fit-content;
`;

const StDiv73 = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const StDiv74 = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 14px;
  width: 100%;
`;

const StDiv75 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv76 = styled.div`
  ${Header3};
  align-self: stretch;
  color: ${contents.primary.value};
`;

const StDiv77 = styled.div`
  ${Header3};
  align-self: stretch;
  color: ${contents.primary.value};
`;

const StDiv78 = styled.div`
  display: flex;
  padding: 16px;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid ${contents.tertiary.value};
  width: 100%;
`;

const StDiv79 = styled.div`
  ${Body1};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv80 = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const StDiv81 = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 100px;
  width: 100%;
`;

const StDiv82 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv83 = styled.div`
  ${Header3};
  align-self: stretch;
  color: ${colors.gray[500].value};
`;

const StDiv84 = styled.div`
  ${Header3};
  align-self: stretch;
  color: ${colors.gray[500].value};
`;

const StDiv85 = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;
  border-radius: 5px;
  border: 1px solid ${contents.tertiary.value};
  width: 100%;
`;

const StDiv86 = styled.div`
  ${Body1};
  color: ${contents.primary.value};
  width: fit-content;
`;
