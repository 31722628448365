import { scroller } from "react-scroll";

const easingType = "easeInOutCubic";
/**
 *
 * @param targetName 스크롤할 Element name프로퍼티명
 * @param offset Y offset
 * @param delay 딜레이시간
 */
export const scrollToElement = (
  targetName: string,
  offset: number,
  delay?: number
) => {
  scroller.scrollTo(targetName, {
    duration: 800,
    delay: delay ? delay : 0,
    smooth: easingType,
    offset,
  });
};

/**
 *
 * @param targetName 스크롤할 Element name프로퍼티명
 * @param offset Y offset
 * @param containerId Element가 속한 컨테이너의 id 명
 */
export const scrollToElementInCont = (
  targetName: string,
  offset: number,
  containerId: string
) => {
  scroller.scrollTo(targetName, {
    duration: 800,
    delay: 0,
    smooth: easingType,
    offset,
    containerId,
  });
};

/**
 * 엘리먼트 스크롤 터치 스크롤 이벤트 토글함수 ( 모바일용 )
 * @param targetElement 타겟 엘리먼트 | 클래스명 혹은 아이디 셀렉터
 * @param mode 스크롤 on / off
 */
export const toggleScrollFeature = (
  targetElement: React.RefObject<HTMLDivElement> | string,
  mode: boolean
) => {
  if (typeof targetElement === "string") {
    const modal = document.querySelector(`${targetElement}`)!;

    if (modal) {
      toggleBodyHeight();
      if (mode) {
        const scrollPosition = window.pageYOffset;
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.width = "100%";
        // modal.addEventListener('touchmove', (e) => scrollFreeze(e));
      } else {
        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("position");
        document.body.style.removeProperty("top");
        document.body.style.removeProperty("width");

        // modal.removeEventListener('touchmove', (e) => scrollFreeze(e));
      }
    }
  } else if (typeof targetElement === "object") {
    toggleBodyHeight();
    if (mode) {
      // targetElement.current!.addEventListener('touchmove', (e) => scrollFreeze(e));
    } else {
      // targetElement.current!.removeEventListener('touchmove', (e) => scrollFreeze(e));
    }
  }
};

/**
 *
 * @param targetName 스크롤할 Element name프로퍼티명
 * @param offset Y offset
 * @param containerId Element가 속한 컨테이너의 id 명
 */
export function asyncScrollToElement(
  targetName: string,
  offset: number,
  containerId: string
) {
  let tryCnt = 0;
  let rafId: number;
  const scroll = () =>
    requestAnimationFrame(() => {
      if (document.querySelector(`div[name="${targetName}"]`)) {
        scrollToElementInCont(targetName, offset, containerId);
        cancelAnimationFrame(rafId);
      } else {
        tryCnt = tryCnt + 1;

        if (tryCnt > 1200) {
          cancelAnimationFrame(rafId);
        } else {
          scroll();
        }
      }
    });
  rafId = scroll();
}

/**
 * 스크롤 이벤트 방지
 * @param e
 */
export function scrollFreeze(e: Event) {
  e.preventDefault();
  e.stopPropagation();
}

/**
 * height를 고정시킴
 */
export function toggleBodyHeight() {
  const vpH = window.innerHeight.toString();

  const htmlTag = document.documentElement;
  const body = document.body;
  htmlTag.style.height = htmlTag.style.height ? "" : `${vpH}px`;
  body.style.height = body.style.height ? "" : `${vpH}px`;
}

/**
 * 스크롤 방지
 * @param e
 */
export const preventScrollEvent = (e: TouchEvent) => {
  e.preventDefault();
};
