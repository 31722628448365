import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  Header1,
  Body1,
  pointerEvt,
  borderRadius,
  foundation,
  Search,
} from "ms-ui";
import styled from "styled-components";
import { Mshuttle } from "./MshuttleImg";

interface IMainSearchBar {
  onClickHandler: () => void;
}

export const MainSearchBar = observer(({ onClickHandler }: IMainSearchBar) => {
  return (
    <>
      <StDiv1>
        <StDiv2>
          <StDiv3>
            <StDiv4>
              집 앞에서 회사까지,
              <br />
              자면서 출근하는 나만의 셔틀
            </StDiv4>
          </StDiv3>
          <StDiv5>
            <StDiv6>
              결제없이 무료로 2일동안 탑승해보세요!
              <br />
              결정은 나중에 해도 괜찮아요!
            </StDiv6>
            <StDiv7 onClick={onClickHandler} data-id={"main_topSearch"}>
              <StDiv8>
                <Search color={"#ffffff"} />
              </StDiv8>
              <StDiv9>
                <StDiv10>어디서 출근하세요?</StDiv10>
              </StDiv9>
            </StDiv7>
          </StDiv5>
        </StDiv2>
        <StDiv11>
          <Mshuttle />
        </StDiv11>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${spacing[24].value};
  flex-direction: column;
  gap: ${spacing[64].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const StDiv4 = styled.div`
  ${Header1};
  flex: 1 0 0;
  color: ${contents.primary.value};
  text-align: center;
`;

const StDiv5 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[24].value};
  width: 100%;
`;

const StDiv6 = styled.div`
  ${Body1};
  align-self: stretch;
  color: ${contents.subtitle.value};
  text-align: center;
`;

const StDiv7 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: 0px ${spacing[16].value};
  cursor: pointer;
  width: 100%;
`;

const StDiv8 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing[12].value};
  gap: 10px;
  border-radius: ${borderRadius[5].value} 0px 0px ${borderRadius[5].value};
  background: ${foundation.normal.primary.value};
  width: 52px;
  height: 52px;
`;

const StDiv9 = styled.div`
  display: flex;
  padding: ${spacing[12].value} ${spacing[16].value};
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 0px ${borderRadius[5].value} ${borderRadius[5].value} 0px;
  box-shadow: -2px 0 0 ${foundation.normal.primary.value} inset,
    0 2px 0 ${foundation.normal.primary.value} inset,
    0 -2px 0 ${foundation.normal.primary.value} inset;

  width: 100%;
`;

const StDiv10 = styled.div`
  ${Body1};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv11 = styled.div`
  display: flex;
  padding: 0px ${spacing[16].value};
  flex-direction: column;
  width: 100%;
`;
