import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, pointerEvt, borderRadius, Subtitle2 } from "ms-ui";
import styled from "styled-components";
import { Notice } from "./Notice";
import { FAQ } from "./FAQ";

interface IMainGuideButtons {
  onNotiHandler: () => void;
  onFaqHandler: () => void;
}

export const MainGuideButtons = observer(
  ({ onNotiHandler, onFaqHandler }: IMainGuideButtons) => {
    return (
      <>
        <StDiv1>
          <StDiv2 onClick={onNotiHandler} data-id={"main_noti"}>
            <StDiv3>
              모두의셔틀
              <br />
              소식을 확인해보세요!
            </StDiv3>
            <Notice />
          </StDiv2>
          <StDiv4 onClick={onFaqHandler} data-id={"main_FAQ"}>
            <StDiv5>
              모두의셔틀에
              <br />
              궁금한 점이 있으신가요?
            </StDiv5>
            <FAQ />
          </StDiv4>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[8].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[16].value};
  gap: 109px;
  border-radius: ${borderRadius[5].value};
  background: ${contents.background.value};
  cursor: pointer;
  width: 100%;
`;

const StDiv3 = styled.div`
  ${Subtitle2};
  flex: 1 0 0;
  color: ${contents.primary.value};
`;

const StDiv4 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[16].value};
  gap: 89px;
  border-radius: ${borderRadius[5].value};
  background: ${contents.background.value};
  cursor: pointer;
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Subtitle2};
  flex: 1 0 0;
  color: ${contents.primary.value};
`;
