import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import { MSHUTTLE_PATH } from "src/constants/addr";
import styled from "styled-components";

import { IPopup, isNil } from "ms_data";
import { getEndOfToday } from "src/func/moment";
import {
  Body2,
  CenterFlex,
  contents,
  isMobile,
  ModalContainer,
  spacing,
} from "ms-ui";
import { CustomIndicators } from "./CustomIndicators";
import { PopupSlide } from "./PopupSlide";
import { Swiper, SwiperSlide } from "src/components/Swiper/Swiper";

interface PopupProps {
  items: IPopup[];
}

const Popup = (props: PopupProps) => {
  const { items } = props;

  const [popupView, setPopupView] = useState<boolean>(
    () => Cookies.get("MSUW_POPUP") === undefined && props.items !== null
  );

  //swiper slideIndex
  const [swiper, setSwiper] = useState<any>(undefined);
  const [activeIndex, SetActiveIndex] = useState(0);

  const swiperOptions = {
    on: {
      _swiper(s) {
        setSwiper(s);
      },
      slideChange(s) {
        SetActiveIndex(s.activeIndex);
      },
    },
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 40,
    initialSlide: activeIndex,
    width: window.innerWidth > 420 ? 420 - 60 : window.innerWidth - 60,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };

  const closePopup = (closeToday: boolean) => {
    if (closeToday) {
      // 오늘 23:59:59 까지 쿠키 유효기간 설정
      const expires: Date = getEndOfToday();
      setCookie(expires);
    }
    setPopupView(false);
  };

  /**
   * 쿠키를 설정한다.
   * @param expires 쿠키 유효기간
   */
  const setCookie = (expires: Date) => {
    Cookies.set("MSUW_POPUP", "true", {
      domain: MSHUTTLE_PATH.domain,
      path: "/",
      expires,
    });
  };

  useEffect(() => {
    if (!isNil(swiper)) swiper.slideTo(activeIndex, 300);
  }, [activeIndex]);

  useEffect(() => {
    if (isMobile === true) {
      const resizeHandler = () => {
        if (!isNil(swiper)) {
          const index = activeIndex < items.length - 1 ? activeIndex + 1 : 0;
          swiper.slideTo(index, 300);
        }
      };

      window.addEventListener("resize", resizeHandler);

      return () => {
        window.removeEventListener("resize", resizeHandler);
      };
    }
  }, [swiper, activeIndex]);

  return (
    <>
      {popupView && items && items.length > 0 ? (
        <ModalContainer>
          <StPopupOuterCtn>
            <div style={{ width: "100%" }}>
              <StPopupIndicators>
                <CustomIndicators
                  onClick={(i: number) => SetActiveIndex(i)}
                  cnt={Math.ceil(items.length / 1)}
                  selected={activeIndex}
                />
              </StPopupIndicators>

              <StPopupInnerCtn>
                <Swiper {...swiperOptions}>
                  {items.map((item, i) => (
                    <SwiperSlide key={i}>
                      <PopupSlide {...item} idx={i} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </StPopupInnerCtn>

              {/* outer btn 영역 */}
              <StPopupIndicatorsBtns>
                <div style={{ width: "50%" }} onClick={() => closePopup(true)}>
                  오늘 하루 보지 않기
                </div>
                |
                <div style={{ width: "50%" }} onClick={() => closePopup(false)}>
                  닫기
                </div>
              </StPopupIndicatorsBtns>
            </div>
          </StPopupOuterCtn>
        </ModalContainer>
      ) : null}
    </>
  );
};

export default Popup;

const StPopupOuterCtn = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - env(safe-area-inset-bottom));
  ${CenterFlex}
`;

const StPopupInnerCtn = styled.div`
  width: 100%;
  padding: 0 30px;
  height: 400px;
  max-height: 400px;

  // mobile && min-width: 576px~ && 가로모드일 때
  @media (pointer: coarse) and (min-width: 576px) and (orientation: landscape) {
    height: calc(100dvh - 80px - env(safe-area-inset-bottom));
  }

  swiper-container {
    overflow: hidden;
    height: 100%;
  }

  swiper-slide {
    width: 100% !important;
    height: 100%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  swiper-slide > a {
    display: block;

    width: 100%;
    height: 100%;

    color: #23282c;
    text-decoration: none;
    background-repeat: no-repeat;
  }

  swiper-slide > a > .content {
    width: 100%;
    height: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;

    font-size: 18px;
    word-break: keep-all;
    white-space: pre-wrap;
    font-weight: 400;

    h4 {
      font-size: 28px;
    }
  }
`;

const StPopupIndicators = styled.div`
  margin-bottom: ${spacing[12].value};
`;

const StPopupIndicatorsBtns = styled.div`
  margin-top: ${spacing[12].value};
  width: 100%;
  ${Body2}
  color: ${contents.tertiary.value};
  display: flex;
  padding: 0 30px;

  div {
    text-align: center;
    cursor: pointer;
  }
`;
