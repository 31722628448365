import React from "react";
import { observer } from "mobx-react";
import {
  Paragraph,
  spacing,
  Header2,
  contents,
  colors,
  Subtitle1,
  foundation,
  Search,
  Body1,
  Body2,
  Button,
  Location,
  Friend,
} from "ms-ui";
import styled from "styled-components";

interface IGuide {
  onSearchHandler: () => void;
  onMakeHandler: () => void;
  onEventHandler: () => void;
}

export const Guide = observer(
  ({ onSearchHandler, onMakeHandler, onEventHandler }: IGuide) => {
    return (
      <>
        <StDiv1 noMargin={true}>
          <StDiv2>
            <StDiv3>
              <StDiv4>이용 가이드</StDiv4>
            </StDiv3>
          </StDiv2>
          <StDiv5>
            <StDiv6>
              <StDiv7>
                <StDiv8>
                  <StDiv9>1. 내 출근길을 찾아보세요!</StDiv9>
                  <StDiv10>
                    <Search color={"#ffffff"} />
                  </StDiv10>
                </StDiv8>
                <StDiv11>
                  우리집과 회사, 출근시간만 입력하세요!
                  <br />
                  매칭된 출근길을 신청하면,
                  <br />
                  2일 무료탑승이 가능해요!
                </StDiv11>
                <StDiv12>*출근길에 탑승/하차지 추가도 가능해요!</StDiv12>
              </StDiv7>
              <Button
                color={"primary"}
                size={"rg"}
                radius={"5"}
                outline={false}
                data-id={"main_guideSearch"}
                width={"100%"}
                onClick={onSearchHandler}
              >
                내 출근길 찾아보기
              </Button>
            </StDiv6>
            <StDiv13>
              <StDiv14>
                <StDiv15>
                  <StDiv16>2. 매칭된 출근길이 없다면?</StDiv16>
                  <StDiv17>
                    <Location color={"#ffffff"} />
                  </StDiv17>
                </StDiv15>
                <StDiv18>
                  내 출근길 알림받기를 등록해보세요!
                  <br />
                  나를 위한 꿀잠 출근길을 만들어드려요
                </StDiv18>
                <StDiv19>
                  * 출근길 만드는데 평균 1달 정도 소요되며, <br /> 비슷한
                  알림요청이 많으면 더 빨리 만들어져요!
                </StDiv19>
              </StDiv14>
              <Button
                color={"primary"}
                size={"rg"}
                radius={"5"}
                outline={false}
                data-id={"main_guideMake"}
                width={"100%"}
                onClick={onMakeHandler}
              >
                내 출근길 알림받기
              </Button>
            </StDiv13>
            <StDiv20>
              <StDiv21>
                <StDiv22>
                  <StDiv23>3. 다함께 할인받고 이용해요!</StDiv23>
                  <StDiv24>
                    <Friend color={"#ffffff"} />
                  </StDiv24>
                </StDiv22>
                <StDiv25>
                  모두의셔틀이 준비한 할인혜택을 확인하세요!
                  <br />
                  나와친구, 동료 모두 다같이 할인 받고 이용해요.
                </StDiv25>
              </StDiv21>
              <Button
                color={"primary"}
                size={"rg"}
                radius={"5"}
                outline={false}
                data-id={"main_guideEvent"}
                width={"100%"}
                onClick={onEventHandler}
              >
                이벤트 보러가기
              </Button>
            </StDiv20>
          </StDiv5>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled(Paragraph)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[24].value};
`;

const StDiv2 = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  background: #fff;
  box-shadow: 0px -10px 0px 0px rgba(79, 211, 252, 0.25) inset;
  width: fit-content;
`;

const StDiv4 = styled.div`
  ${Header2};
  color: #1a202c;
  text-align: center;
  width: fit-content;
`;

const StDiv5 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const StDiv6 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[12].value};
  border-radius: 5px;
  border: 1px solid ${contents.tertiary.value};
  width: 100%;
`;

const StDiv7 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const StDiv8 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StDiv9 = styled.div`
  ${Subtitle1};
  flex: 1 0 0;
  color: ${colors.gray[500].value};
`;

const StDiv10 = styled.div`
  display: flex;
  padding: ${spacing[4].value};
  border-radius: 16px;
  background: ${foundation.normal.primary.value};
  width: fit-content;
`;

const StDiv11 = styled.div`
  ${Body1};
  align-self: stretch;
  color: ${contents.subtitle.value};
`;

const StDiv12 = styled.div`
  ${Body2};
  align-self: stretch;
  color: ${colors.mainblue[400].value};
`;

const StDiv13 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[12].value};
  border-radius: 5px;
  border: 1px solid ${contents.tertiary.value};
  width: 100%;
`;

const StDiv14 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const StDiv15 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StDiv16 = styled.div`
  ${Subtitle1};
  height: 23.828px;
  flex: 1 0 0;
  color: ${colors.gray[500].value};
`;

const StDiv17 = styled.div`
  display: flex;
  padding: ${spacing[4].value};
  border-radius: 16px;
  background: ${foundation.normal.primary.value};
  width: fit-content;
`;

const StDiv18 = styled.div`
  ${Body1};
  height: 47.656px;
  align-self: stretch;
  color: ${contents.subtitle.value};
`;

const StDiv19 = styled.div`
  ${Body2};
  height: 41.699px;
  align-self: stretch;
  color: ${colors.mainblue[400].value};
`;

const StDiv20 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[12].value};
  border-radius: 5px;
  border: 1px solid ${contents.tertiary.value};
  width: 100%;
`;

const StDiv21 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const StDiv22 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StDiv23 = styled.div`
  ${Subtitle1};
  flex: 1 0 0;
  color: ${contents.primary.value};
`;

const StDiv24 = styled.div`
  display: flex;
  padding: ${spacing[4].value};
  border-radius: 50px;
  background: ${foundation.normal.primary.value};
  width: fit-content;
`;

const StDiv25 = styled.div`
  ${Body1};
  align-self: stretch;
  color: ${contents.subtitle.value};
`;
