import React, { useRef } from "react";
import { observer } from "mobx-react";
import {
  spacing,
  colors,
  Header3,
  Body1,
  foundation,
  Subtitle4,
  Body3,
  contents,
} from "ms-ui";
import styled from "styled-components";
import useDraggableScroll from "./DraggableScroll";

interface IShuttleInfo {
  list: { seater: number; img: string }[];
}

export const ShuttleInfo = observer(({ list }: IShuttleInfo) => {
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref, {
    direction: "horizontal",
  });
  return (
    <>
      <StDiv1>
        <StDiv2>
          <StDiv3>집 앞으로 셔틀이 모시러 갈게요!</StDiv3>
          <StDiv4>상황에 맞는 다양한 셔틀 차량이 운행 중입니다.</StDiv4>
        </StDiv2>

        <StContent ref={ref} onMouseDown={onMouseDown}>
          <div style={{ width: spacing[16].value }} />
          {list.map((item, i) => (
            <StDiv7 key={i}>
              <StDiv8>
                <StDiv9>{item.seater}</StDiv9>
                <StDiv10>{`인승`}</StDiv10>
              </StDiv8>
              <StDiv11>
                <img src={item.img} alt={""} />
              </StDiv11>
            </StDiv7>
          ))}
          <div style={{ width: spacing[16].value }} />
        </StContent>
      </StDiv1>
    </>
  );
});

const StContent = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  gap: ${spacing["12"].value};

  img {
    width: 100%;
    height: 100%;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
`;

const StDiv1 = styled.div`
  display: flex;
  padding: ${spacing[24].value} 0px ${spacing[16].value} 0px;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[24].value};
  background: ${colors.mainblue["040"].value};
  width: 100%;
`;

const StDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StDiv3 = styled.div`
  ${Header3};
  color: ${colors.gray[500].value};
  width: fit-content;
`;

const StDiv4 = styled.div`
  ${Body1};
  color: ${colors.gray[500].value};
  text-align: center;
  width: fit-content;
`;

const StDiv7 = styled.div`
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: flex-end;
  gap: -44px;
  width: fit-content;
`;

const StDiv8 = styled.div`
  display: flex;
  padding: 4px 10px;
  flex-direction: column;
  align-items: center;
  border-radius: 22px;
  background: ${foundation.normal.secondary.value};
  margin-bottom: -44px;
  z-index: 1;
  width: fit-content;
`;

const StDiv9 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  text-align: center;
  width: fit-content;
`;

const StDiv10 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  text-align: center;
  width: fit-content;
`;

const StDiv11 = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 60px;
  background: ${contents.secondary.value};
`;
