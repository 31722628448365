import { ServiceReview } from "./ServiceReview";

export const ServiceReviewView = () => {
  const reviewConst = [
    {
      title: `"지옥철 안타서 좋아요!"`,
      content: (
        <>
          어제부터 #모두의셔틀 타고, 앉아서 출근!
          <br />
          이렇게 좋은걸 이제야 알게 되다니 ㅠㅠㅠㅠ
          <br />
          아침에 #지옥철 안타니 너무 너무 편하당😍
        </>
      ),
      writer: "- 분당에서 여의도로 출근 / 김** 님 -",
      star: 5,
    },
    {
      title: `“진 빠졌던 기억은 이제 안녕”`,
      content: (
        <>
          자면서 출근하는게 이렇게 편한거라고?
          <br />
          1시간 넘게 계속 서서 가느라 진 빠졌던거
          <br />
          생각하면 돌아갈 수 없을것 같다.
          <br />
          아침 여유는 덤. 삶의 질이 달라지는 게<br />
          느껴진다.
        </>
      ),
      writer: "- 회기에서 판교로로 출근 / 김** 님 -",
      star: 4,
    },
    {
      title: `“모두의 셔틀 탄지 벌써 1년 5개월”`,
      content: (
        <>
          아침 지옥철은 생각조차 나지 않는다.
          <br />
          이 서비스를 만든 사람은 천재인가?
          <br />
          출근하면서 메일도 보내고 영어공부도 하고,
          <br />
          그동안 지하철에서 버린 시간이 너무 아깝다.
        </>
      ),
      writer: "- 용인에서 여의도로 출근 / 이** 님 -",
      star: 5,
    },
    {
      title: `“삶의 질이 많이 올라간 느낌”`,
      content: (
        <>
          신청하고 1달만에 탑승 성공!
          <br />
          만들어 주셔서 정말정말 감사합니다.🤣🤣🤣
          <br />
          아침에 조금 일찍 출근하지만,
          <br />
          자면서 출근하는건 못참지😴😴
          <br />
          이제 난 지옥철로 돌아갈수가 없어…
        </>
      ),
      writer: "- 노원에서 판교로 출근 / 박** 님 -",
      star: 5,
    },
  ];

  return <ServiceReview list={reviewConst} />;
};
