export const FAQ = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2 5.79995L40 5.79995C41.768 5.79995 43.2 7.23195 43.2 8.99995V26.5119V35.8719L37.28 29.9519L37.048 29.7199H36.72L15.2 29.7199C13.432 29.7199 12 28.2879 12 26.5199L12 8.99995C12 7.23195 13.432 5.79995 15.2 5.79995Z"
        fill="white"
      />
      <path
        d="M15.2 6.59993C13.88 6.59993 12.8 7.67993 12.8 8.99993L12.8 26.5119C12.8 27.8319 13.88 28.9119 15.2 28.9119L36.712 28.9119H37.376L37.84 29.3759L42.4 33.9359V26.5119V8.99993C42.4 7.67993 41.32 6.59993 40 6.59993L15.2 6.59993ZM15.2 4.99993H40C42.208 4.99993 44 6.79193 44 8.99993V26.5119V37.7999L36.712 30.5119L15.2 30.5119C12.992 30.5119 11.2 28.7199 11.2 26.5119V8.99993C11.2 6.79193 12.992 4.99993 15.2 4.99993Z"
        fill="#1A202C"
      />
      <path
        d="M4.80005 43.072V34.92V34.904V34.888C4.80005 34.832 4.80005 34.776 4.80005 34.72V25.592C4.80005 23.056 6.85605 21 9.39205 21H23.408C25.944 21 28 23.056 28 25.592V34.096C28 36.624 25.944 38.688 23.408 38.688H9.51205H9.17605L8.94405 38.92L4.80005 43.072Z"
        fill="#4FD3FC"
      />
      <path
        d="M5.6 41.1359L8.376 38.3599L8.848 37.8879H9.512H23.408C25.496 37.8879 27.2 36.1839 27.2 34.096V25.592C27.2 23.504 25.496 21.8 23.408 21.8H9.392C7.304 21.8 5.6 23.504 5.6 25.592V34.7199C5.6 34.7679 5.6 34.8159 5.6 34.8639V34.896V34.9279V41.1359ZM4 44.9999V34.9199C4 34.8559 4 34.7839 4 34.7199V25.592C4 22.616 6.416 20.2 9.392 20.2H23.408C26.384 20.2 28.8 22.616 28.8 25.592V34.096C28.8 37.0719 26.384 39.4879 23.408 39.4879H9.512L4 44.9999Z"
        fill="#1A202C"
      />
      <path
        d="M15.5789 30.9746H17.0109C16.8509 29.3986 18.8029 28.8626 18.8029 27.2706C18.8029 25.8386 17.8109 25.1426 16.4669 25.1426C15.5629 25.1506 14.6989 25.5586 14.1149 26.2626L15.0189 27.0946C15.3309 26.7186 15.7789 26.4946 16.2589 26.4706C16.7389 26.4226 17.1629 26.7826 17.2109 27.2626C17.2109 27.3026 17.2189 27.3506 17.2109 27.3906C17.2189 28.4546 15.3149 29.1746 15.5789 30.9746ZM16.2989 33.9586C16.8669 33.9586 17.3309 33.4946 17.3309 32.9266C17.3309 32.9106 17.3309 32.8946 17.3309 32.8786C17.3549 32.3106 16.9149 31.8306 16.3469 31.8066C16.3309 31.8066 16.3149 31.8066 16.2989 31.8066C15.7309 31.8146 15.2749 32.2786 15.2749 32.8466C15.2749 32.8626 15.2749 32.8706 15.2749 32.8866C15.2509 33.4546 15.6829 33.9346 16.2509 33.9666C16.2749 33.9586 16.2829 33.9586 16.2989 33.9586Z"
        fill="#1A202C"
      />
    </svg>
  );
};
