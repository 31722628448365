export const Notice = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9091 20.3829C23.1727 20.3829 25.8182 23.0453 25.8182 26.3297V40.0531C25.8182 43.3376 23.1727 46 19.9091 46C16.6455 46 14 43.3376 14 40.0531V26.3297C14 23.0453 16.6455 20.3829 19.9091 20.3829Z"
        fill="white"
      />
      <path
        d="M19.9091 21.2979C22.6727 21.2979 24.9091 23.5485 24.9091 26.3298V40.0532C24.9091 42.8344 22.6727 45.0851 19.9091 45.0851C17.1454 45.0851 14.9091 42.8344 14.9091 40.0532V26.3298C14.9091 23.5485 17.1454 21.2979 19.9091 21.2979Z"
        stroke="#1A202C"
        strokeWidth="2"
      />
      <path
        d="M7.63636 13.9788H15.8182V31.3617H6.72727C5.21818 31.3617 4 30.1358 4 28.6171V17.6383C4 15.6164 5.62727 13.9788 7.63636 13.9788Z"
        fill="#4FD3FC"
      />
      <path
        d="M7.63633 14.8937H14C14.5 14.8937 14.9091 15.3054 14.9091 15.8086V29.532C14.9091 30.0352 14.5 30.4469 14 30.4469H6.72724C5.72724 30.4469 4.90906 29.6235 4.90906 28.6171V17.6384C4.90906 16.1196 6.12724 14.8937 7.63633 14.8937Z"
        stroke="#1A202C"
        strokeWidth="2"
      />
      <path
        d="M41.7782 41.4713L14.9328 30.7213L14.9055 15.0034L41.7873 3.92407C42.5237 4.00641 43.0964 4.62854 43.0964 5.3879V40.0075C43.0873 40.7668 42.5146 41.3981 41.7782 41.4713Z"
        fill="white"
      />
      <path
        d="M41.8601 4.88468L15.8146 15.6164L15.8419 30.0991L41.8601 40.5106C42.051 40.4191 42.1874 40.227 42.1874 39.9983V5.38787C42.1783 5.15915 42.051 4.96702 41.8601 4.88468ZM41.6237 3C42.9328 3 43.9965 4.07043 43.9965 5.38787V40.0074C43.9965 41.3249 42.9328 42.3953 41.6237 42.3953L14.0237 31.3434L13.9965 14.3904L41.6237 3Z"
        fill="#1A202C"
      />
    </svg>
  );
};
