import React from "react";
import styled from "styled-components";

export const CustomIndicators: React.FC<{
  cnt: number;
  onClick: (i: number) => void;
  selected: number;
  color?: "white";
}> = ({ cnt, onClick, selected, color }) => (
  <>
    <SwipeCtnNav color={color}>
      <StSwipeControlNav color={color}>
        {Array.from({ length: cnt }).map((_, i, arr) => (
          <StSwipeControlNavBtn
            key={i}
            onClick={() => onClick(i)}
            $active={i === selected ? true : false}
            color={color}
            value="0"
            role="button"
            aria-label="slide item 1"
          ></StSwipeControlNavBtn>
        ))}
      </StSwipeControlNav>
    </SwipeCtnNav>
  </>
);

const SwipeCtnNav = styled.div<{ color?: string }>`
  position: relative;
  ${(p) =>
    p.color === "white" ? "transform: translate(0, -100%);z-index: 1;" : ""}
  .control-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .swiper-pagination {
  }
`;

const StSwipeControlNav = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const StSwipeControlNavBtn = styled.li<{ $active: boolean; color?: string }>`
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: var(--border-radius-5);

  margin-right: var(--spacing-4);

  background-color: ${(p) =>
    p.$active === true
      ? p.color === "white"
        ? "var(--colors-gray-000)"
        : "var(--colors-subblue-500)"
      : `var(--colors-mainblue-050)`};

  &:last-child {
    .nav {
      margin-right: 0;
    }
  }
`;
