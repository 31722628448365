export const Shuttle = () => {
  return (
    <svg
      width="100"
      height="38"
      viewBox="0 0 100 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1477_4780)">
        <path d="M0 21.9399H17.01" stroke="#4FD3FC" />
        <path d="M8.51001 17H17.01" stroke="#4FD3FC" />
        <path
          d="M18.9974 13.0176V27.8208C18.9974 29.7072 20.5378 31.2336 22.4325 31.2336H96.5649C98.4596 31.2336 99.9928 29.7072 100 27.8208C100 27.72 99.9928 27.6192 99.9855 27.5184L99.313 20.0088C99.2696 19.5408 99.1322 19.0872 98.908 18.6768L97.7292 16.5312L92.0017 6.08401L91.7703 5.65921L91.2279 4.66561C90.6277 3.57121 89.4706 2.88721 88.2123 2.88721H23.7631C22.1215 2.88721 20.7114 4.03921 20.3932 5.64481L19.0697 12.3696C19.0191 12.5784 18.9974 12.7944 18.9974 13.0176Z"
          fill="#55C1F0"
        />
        <path
          d="M28.8505 31.3409C28.8939 27.5897 31.9675 24.5657 35.7355 24.5657C39.5034 24.5657 42.5771 27.5897 42.6205 31.3409H28.8505Z"
          fill="#111113"
        />
        <path
          d="M35.7355 36.9998C38.831 36.9998 41.3404 34.5016 41.3404 31.4198C41.3404 28.3381 38.831 25.8398 35.7355 25.8398C32.64 25.8398 30.1306 28.3381 30.1306 31.4198C30.1306 34.5016 32.64 36.9998 35.7355 36.9998Z"
          fill="#18151C"
        />
        <path
          d="M32.3943 31.4201C32.3943 33.2561 33.8913 34.7465 35.7355 34.7465C37.5797 34.7465 39.0768 33.2561 39.0768 31.4201C39.0768 29.5841 37.5797 28.0938 35.7355 28.0938C33.8913 28.0938 32.3943 29.5841 32.3943 31.4201Z"
          fill="#BBBBBD"
        />
        <path
          d="M75.6932 31.3409C75.7366 27.5897 78.8103 24.5657 82.5782 24.5657C86.3462 24.5657 89.4198 27.5897 89.4632 31.3409H75.6932Z"
          fill="#111113"
        />
        <path
          d="M82.5783 36.9998C85.6738 36.9998 88.1832 34.5016 88.1832 31.4198C88.1832 28.3381 85.6738 25.8398 82.5783 25.8398C79.4828 25.8398 76.9734 28.3381 76.9734 31.4198C76.9734 34.5016 79.4828 36.9998 82.5783 36.9998Z"
          fill="#18151C"
        />
        <path
          d="M82.5783 34.7465C84.4236 34.7465 85.9196 33.2573 85.9196 31.4201C85.9196 29.583 84.4236 28.0938 82.5783 28.0938C80.733 28.0938 79.2371 29.583 79.2371 31.4201C79.2371 33.2573 80.733 34.7465 82.5783 34.7465Z"
          fill="#BBBBBD"
        />
        <path
          d="M19.0192 23.7671H20.8489C21.0803 23.7671 21.2611 23.9471 21.2611 24.1775V28.0439C21.2611 28.2743 21.0731 28.4543 20.8489 28.4543H19.4965C19.2289 28.4543 19.0192 28.2383 19.0192 27.9791V23.7671Z"
          fill="#E6E5E5"
        />
        <path
          d="M83.8914 6.71746V15.4583C83.8914 16.0487 84.3687 16.5239 84.9617 16.5239H97.7265L91.9986 6.07666L91.7672 5.65186H84.9617C84.3687 5.65186 83.8914 6.12706 83.8914 6.71746Z"
          fill="#0C0D10"
        />
        <path
          d="M22.6388 7.25026V15.4583C22.6388 16.0487 23.1233 16.5311 23.7163 16.5311H80.5577C81.44 16.5311 82.1631 15.8183 82.1631 14.9399V6.72466C82.1631 6.13426 81.6786 5.65186 81.0856 5.65186H24.2442C23.3547 5.65186 22.6388 6.36466 22.6388 7.25026Z"
          fill="#0C0D10"
        />
        <path
          d="M74.5732 28.1736H44.6194"
          stroke="#D5F0FB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M96.6736 28.2815H90.7866"
          stroke="#D5F0FB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M27.2204 28.0801H23.2427"
          stroke="#D5F0FB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M23.2426 3.16073V2.07354C23.192 1.54794 23.568 1.07273 24.096 1.00073H37.9528C38.1408 1.00073 38.3216 1.07273 38.459 1.20953L39.8765 3.16073H23.2426Z"
          fill="#D5F0FB"
        />
        <path
          d="M35.3626 19.4954C34.8998 19.4954 34.5237 19.8698 34.5237 20.3306C34.5237 20.7914 34.8998 21.1658 35.3626 21.1658C35.8255 21.1658 36.2015 20.7914 36.2015 20.3306C36.2015 19.877 35.8255 19.5026 35.3626 19.4954ZM35.3626 20.5754C35.2324 20.5754 35.1239 20.4674 35.1239 20.3378C35.1239 20.2082 35.2324 20.1002 35.3626 20.1002C35.4928 20.1002 35.6013 20.2082 35.6013 20.3378C35.6013 20.4674 35.4928 20.5754 35.3626 20.5754Z"
          fill="white"
        />
        <path
          d="M31.8851 21.173V19.4954H30.2073V22.2458H31.8851V21.6554H30.8075V21.173H31.8851ZM31.2849 20.5754H30.8075V20.1002H31.2849V20.5754Z"
          fill="#6078EA"
        />
        <path
          d="M42.6885 21.1728V20.5752H41.6109V20.1H42.6885V19.5024H41.0106V21.1728H42.6885Z"
          fill="#6078EA"
        />
        <path
          d="M42.6884 23.3257V22.7281H42.0375L43.1151 21.6553H41.0106V22.2529H41.6687L40.5839 23.3257H42.6884Z"
          fill="#6078EA"
        />
        <path
          d="M42.6957 18.4226H41.0106V19.0202H42.6957V18.4226Z"
          fill="#6078EA"
        />
        <path
          d="M36.2088 21.6519H34.5237V22.2495H36.2088V21.6519Z"
          fill="#6078EA"
        />
        <path
          d="M37.2863 19.499H36.686V21.1766H37.2863V19.499Z"
          fill="#6078EA"
        />
        <path
          d="M32.3623 23.3258H32.9626V22.2458H34.0402V21.6482H32.3623V23.3258Z"
          fill="#6078EA"
        />
        <path
          d="M32.3623 21.1728H34.0402V20.5752H32.9626V20.1H34.0402V19.5024H32.3623V21.1728Z"
          fill="#6078EA"
        />
        <path
          d="M39.1449 19.4954V20.093H39.926V20.5682H39.2679L38.9062 20.2082V19.5026H38.306V20.2154L37.6406 20.8778L38.0673 21.3026L38.6097 20.7626L39.1521 21.3026L39.2751 21.1802H39.9332V22.253H40.5335V19.5026L39.1449 19.4954Z"
          fill="#6078EA"
        />
        <path
          d="M26.2367 19.9057H23.474V22.6561H22.8738V19.3081H26.2367V19.9057Z"
          fill="#6078EA"
        />
        <path
          d="M26.2368 22.6563H26.8949C27.0179 22.9803 27.3289 23.1963 27.676 23.1963C28.1389 23.1963 28.5149 22.8219 28.5149 22.3611C28.5149 21.9003 28.1389 21.5259 27.676 21.5259C27.3289 21.5259 27.0179 21.7419 26.8949 22.0659H26.2368V22.6563ZM27.4374 22.3539C27.4374 22.2243 27.5458 22.1163 27.676 22.1163C27.8062 22.1163 27.9147 22.2243 27.9147 22.3539C27.9147 22.4835 27.8062 22.5915 27.676 22.5915C27.5458 22.5987 27.4374 22.4907 27.4374 22.3539Z"
          fill="white"
        />
        <path
          d="M23.9514 22.3538C23.9514 22.8146 24.3275 23.189 24.7903 23.189C25.1375 23.189 25.4485 22.973 25.5786 22.649H26.2368V22.0586H25.5786C25.4557 21.7346 25.1447 21.5186 24.7976 21.5186C24.3347 21.5186 23.9586 21.893 23.9514 22.3538ZM24.5517 22.3538C24.5517 22.2242 24.6602 22.1162 24.7903 22.1162C24.9205 22.1162 25.029 22.2242 25.029 22.3538C25.029 22.4834 24.9205 22.5914 24.7903 22.5914C24.6602 22.5986 24.5517 22.4906 24.5517 22.3538Z"
          fill="#6078EA"
        />
        <path
          d="M29.5998 19.3081V22.6561H28.9995V19.9057H26.2368V19.3081H29.5998Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1477_4780">
          <rect width="100" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
