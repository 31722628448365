import { useEffect, useState } from "react";

import { AxiosResponse } from "axios";

import { commonMsg } from "src/constants/textTpl";
import { winOpen } from "src/func/window";
import { scrollToElement } from "src/func/scroll";
import SearchRouteMapUrl from "../../routes/search";

import { PopupResponse } from "src/interface/response/Common/popup";
import Popup from "./views/popup";

import { MainSearchBar } from "./views/MainSection/MainSearchBar";
import { ServiceSection } from "./views/MainSection/ServiceSection";
import { GuideView } from "./views/MainSection/Guide";
import { MemberShip } from "./views/MainSection/Membership";
import { MainGuideButtons } from "./views/MainSection/MainGuideButtons";
import { ShuttleInfoView } from "./views/MainSection/ShuttleInfo";
import { ServiceReviewView } from "./views/MainSection/ServiceReview";

import { MainContactView } from "./views/MainSection/MainContact";
import { Logo } from "./views/MainSection/logo";
import { FooterComp } from "./views/Footer";
import { MainToolTip } from "./views/MainSection/ToolTip/ToolTip";

import { ModalAlert, Nav, spacing } from "ms-ui";
import { Element } from "react-scroll";
import { useURI } from "ms_router";
import styled from "styled-components";
import { setLS } from "src/func/storage";
import { useLocation } from "react-router-dom";
import { cookie } from "ms-ui";
import { get_popup_list } from "src/repository/Common/popup";

const MainPage = () => {
  const { navigate } = useURI();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [data, setData] = useState<PopupResponse | undefined>(undefined);

  useEffect(() => {
    const { req, cancel } = get_popup_list();
    (async () => {
      try {
        if (cookie.get("MSUW_POPUP") === true) return;
        const res: AxiosResponse<PopupResponse> = await req();
        setData(res.data);
      } catch (e) {
        setError(true);
      }
    })();
    return () => cancel();
  }, []);

  /**
   * 이용가이드, 서비스소개 섹션으로
   */
  const autoScroll = (state: any) => {
    setTimeout(() => {
      if (state) {
        if (state === "intro") {
          scrollToElement("Service", -67);
        } else if (state === "guide") {
          scrollToElement("UserGuide", 67);
        }

        navigate(location.pathname, {
          replace: true,
          state: undefined,
        });
      }
    }, 0);
  };

  useEffect(() => {
    if (location.state) {
      autoScroll(location.state);
    }
  }, []);

  return (
    <>
      <ModalAlert
        open={error}
        onClick={() => setError(false)}
        msg={commonMsg.fail}
      />

      {data && data.popupList && <Popup items={data.popupList} />}

      <StNav>
        <Nav
          left={{
            type: "custom",
            onClick: () => {},
            svg: <Logo />,
          }}
        />
      </StNav>

      {/* 집 앞에서 회사까지 */}
      <MainSearchBar
        onClickHandler={() => {
          setLS("MAIN_SEARCH_TOOTIP_CLOSED", true);
          navigate("/search/start_form");
        }}
      />

      {/* 80,000명의 출근길을 모셔드렸어요! */}
      <ServiceReviewView />

      {/* 매일 아침, 상쾌하게 출근하는 방법! */}
      <Element name={"Service"}>
        <ServiceSection
          onSearchHandler={() => {
            navigate(SearchRouteMapUrl.subPath("start_form"));
          }}
        />
      </Element>

      {/* 1위 출퇴근 멤버십 셔틀 서비스! */}
      <MemberShip />

      {/* 이용 가이드 */}
      <Element name="UserGuide">
        <GuideView />
      </Element>

      {/* 집 앞으로 셔틀이 모시러 갈게요! */}
      <ShuttleInfoView />

      {/* 모두의셔틀 소식을 확인해보세요! */}
      <MainGuideButtons
        onNotiHandler={() => {
          winOpen("https://guide.modooshuttle.com/noti");
        }}
        onFaqHandler={() => {
          winOpen("https://guide.modooshuttle.com/faq");
        }}
      />

      {/* tooTip */}
      <MainToolTip />

      {/* 모두의셔틀 비즈니스, 기사 */}
      <MainContactView />

      {/* Footer */}
      <FooterComp />

      <div style={{ paddingBottom: spacing[60].value }} />
    </>
  );
};

export default MainPage;

const StNav = styled.div`
  & > div > div {
    cursor: default;
  }
`;
