import { MSHUTTLE_ASSETS, ms_file } from "src/constants/addr";
import { ShuttleInfo } from "./ShuttleInfoView";

export const ShuttleInfoView = () => {
  const shuttles = [
    {
      seater: 12,
      //   img: `${ms_file}/image/bus/bus_12.png`,
      img: `${MSHUTTLE_ASSETS}/images/main/img_bus_12.png`,
    },
    {
      seater: 20,
      //   img: `${ms_file}/image/bus/bus_20.png`,
      img: `${MSHUTTLE_ASSETS}/images/main/img_bus_15.png`,
    },
    {
      seater: 35,
      //   img: `${ms_file}/image/bus/bus_35.png`,
      img: `${MSHUTTLE_ASSETS}/images/main/img_bus_35.png`,
    },
    {
      seater: 45,
      //   img: `${ms_file}/image/bus/bus_45.png`,
      img: `${MSHUTTLE_ASSETS}/images/main/img_bus_45.png`,
    },
  ];

  return (
    <>
      <ShuttleInfo list={shuttles} />
    </>
  );
};
