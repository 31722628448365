export const Business = () => {
  return (
    <svg
      width="52"
      height="50"
      viewBox="0 0 52 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2940_10799)">
        <path
          d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
          fill="#656FFF"
        />
        <path d="M32 7H10V42H32V7Z" fill="white" />
        <path d="M31 8H11V41H31V8Z" stroke="#1A202C" strokeWidth="2" />
        <path d="M52 14H30V42H52V14Z" fill="white" />
        <path d="M51 15H31V41H51V15Z" stroke="#1A202C" strokeWidth="2" />
        <path d="M20 13H16V17H20V13Z" fill="#FFD800" />
        <path d="M20 20H16V24H20V20Z" fill="#FFD800" />
        <path d="M39 20H35V24H39V20Z" fill="#FFD800" />
        <path d="M27 13H23V17H27V13Z" fill="#FFD800" />
        <path d="M46 20H42V24H46V20Z" fill="#FFD800" />
        <path d="M39 27H35V31H39V27Z" fill="#FFD800" />
      </g>
      <defs>
        <clipPath id="clip0_2940_10799">
          <rect width="52" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
