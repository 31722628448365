import { IPopup, isNil } from "ms_data";
import { ms_file } from "src/constants/addr";
import styled, { css } from "styled-components";

export const PopupSlide = ({
  img,
  link,
  idx,
  popupId,
}: IPopup & { idx: number }) => {
  return (
    <StA
      // id={`popup_${idx}_${popupId}`}
      href={link || undefined}
      img={img}
      rel="noreferrer"
    >
      <div className={"content"}>
        <h4>&nbsp;</h4>
      </div>
    </StA>
  );
};

const StA = styled.a<{ img: string }>`
  ${(p) =>
    !isNil(p.img)
      ? css`
          background-image: url(${ms_file}/${p.img});
          background-size: contain;
          background-position: center;

          @media (max-width: 420px) {
            background-size: cover;
          }
        `
      : undefined}
`;
