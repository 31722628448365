import { observer } from "mobx-react";
import { Guide } from "./GuideView";
import SearchRouteMapUrl from "../../../../../routes/search";
import { useURI } from "ms_router";
import { winOpen } from "src/func/window";
import { MSHUTTLE_PATH } from "src/constants/addr";

export const GuideView = observer(() => {
  const { navigate } = useURI();

  return (
    <Guide
      onSearchHandler={() => {
        navigate(SearchRouteMapUrl.subPath("start_form"));
      }}
      onMakeHandler={() => {
        winOpen(MSHUTTLE_PATH.make);
      }}
      onEventHandler={() => {
        winOpen("https://guide.modooshuttle.com/event");
      }}
    />
  );
});
