export const Star = ({ color }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.13932 0.562451L5.18944 4.70218L0.819729 5.36214C0.269765 5.47213 0.0902117 6.0021 0.0197812 6.55206C0.0597786 6.75205 0.149773 6.93204 0.289764 7.07203L3.44956 10.2918L2.69961 14.8415C2.58961 15.3615 2.92959 15.8715 3.44956 15.9814C3.66954 16.0214 3.88953 15.9914 4.08952 15.8915L7.99926 13.7416L11.909 15.8915C12.389 16.1314 12.9689 15.9414 13.2089 15.4715C13.3089 15.2815 13.3389 15.0515 13.2989 14.8415L12.549 10.2918L15.7088 7.07203C16.0987 6.67205 16.0987 6.03209 15.6988 5.64212C15.5588 5.50213 15.3788 5.40214 15.1788 5.37214L10.8091 4.70218L8.8592 0.562451C8.64922 0.0824818 8.08926 0.127505 7.61929 0.0824818C7.4093 0.182475 7.23931 0.352464 7.13932 0.562451Z"
        fill={color || "#FFC700"}
      />
    </svg>
  );
};
