import React, { ReactElement, useState } from "react";
import { observer } from "mobx-react";
import {
  spacing,
  foundation,
  Header3,
  contents,
  Body1,
  Paragraph,
  borderRadius,
  colors,
  Body2,
  isMobile,
} from "ms-ui";
import styled from "styled-components";
import { Star } from "./Star";
import { Swiper, SwiperSlide } from "../../../../../components/Swiper/Swiper";
import { SwiperOptions } from "swiper/types";

interface IServiceReview {
  list: {
    title: string;
    writer: string;
    star: number;
    content: ReactElement;
  }[];
}

export const ServiceReview = observer(({ list }: IServiceReview) => {
  const [swiper, setSwiper] = useState<any>();
  const [selected, setSelected] = useState(0);

  const swiperOptions: SwiperOptions = {
    on: {
      _swiper(s) {
        setSwiper(s);
      },
      slideChange(s) {
        setSelected(s.activeIndex);
      },
    },
  };

  let screen = 420;

  if (isMobile === true) {
    if (window.innerWidth < 576) {
      screen = window.innerWidth;
    }
  }

  return (
    <>
      <StDiv1>
        <StDiv2>
          <StDiv3>
            <StDiv4>
              <StDiv5>80,000명</StDiv5>
              <StDiv6>의 출근길을</StDiv6>
            </StDiv4>
            <StDiv7>편하게 모셔드렸어요!</StDiv7>
          </StDiv3>
          <StDiv8>
            직접 탑승해 본 멤버들의
            <br />
            후기를 확인해보세요.
          </StDiv8>
        </StDiv2>

        <StDiv11>
          <StMsSwiper
            {...swiperOptions}
            width={screen - 16 - 8 - 12}
            spaceBetween={8}
            grabCursor={true}
          >
            {list.map((item, i) => (
              <SwiperSlide key={i}>
                <StCard noMargin={true}>
                  <StDiv13>
                    <StDiv14>{item.title}</StDiv14>
                    <StDiv15>
                      <StDiv16>
                        <StDiv17>
                          <StDiv18>
                            {Array.from({ length: 5 }, (_, i) => (
                              <Star
                                key={i}
                                color={
                                  i > item.star - 1 ? "#E8E8E8" : undefined
                                }
                              />
                            ))}
                          </StDiv18>
                        </StDiv17>
                        <StDiv19>{item.star}</StDiv19>
                      </StDiv16>
                      <StDiv20>{item.content}</StDiv20>
                    </StDiv15>
                  </StDiv13>
                  <StDiv21>{item.writer}</StDiv21>
                </StCard>
              </SwiperSlide>
            ))}
          </StMsSwiper>

          <StPagination>
            {Array.from({ length: list.length }, (_, i) => (
              <StBullet
                key={i}
                $isActive={i === selected}
                onClick={() => {
                  swiper?.slideTo(i, 300);
                }}
              />
            ))}
          </StPagination>
        </StDiv11>
      </StDiv1>
    </>
  );
});

const StPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: ${spacing[16].value};
`;

const StBullet = styled.div<{ $isActive: boolean }>`
  width: 10px;
  height: 10px;
  cursor: pointer;
  border-radius: ${borderRadius[5].value};
  margin-right: ${spacing[4].value};

  background-color: ${(p) =>
    p.$isActive === true
      ? `${foundation.normal.secondary.value}`
      : `${colors.mainblue["050"].value}`};
`;

const StMsSwiper = styled(Swiper)`
  display: flex;
  width: 100%;

  & > {
    &:first-child {
      margin-left: ${spacing[16].value};
    }

    &:last-child {
      margin-right: ${spacing[16].value};
    }
  }
`;

const StCard = styled(Paragraph)`
  display: flex;
  height: 264px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadius["10"].value};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
`;

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[24].value};
  background: ${foundation.light.primary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  padding: ${spacing[24].value} ${spacing[16].value} 0px ${spacing[16].value};
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

const StDiv4 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Header3};
  color: ${foundation.normal.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Header3};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv7 = styled.div`
  ${Header3};
  color: ${contents.primary.value};
  text-align: center;
  width: 100%;
`;

const StDiv8 = styled.div`
  ${Body1};
  color: ${contents.subtitle.value};
  text-align: center;
  width: 100%;
`;

const StDiv11 = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${spacing[16].value};
  flex-direction: column;
  align-items: center;
  gap: ${spacing[16].value};
`;

const StDiv13 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: 100%;
`;

const StDiv14 = styled.div`
  ${Header3};
  width: 288px;
  color: ${colors.gray[500].value};
`;

const StDiv15 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
  width: 100%;
`;

const StDiv16 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv17 = styled.div`
  display: flex;
  width: 100%;
`;

const StDiv18 = styled.div`
  display: flex;
  gap: 2px;
  width: 100%;
`;

const StDiv19 = styled.div`
  ${Body1};
  color: ${contents.accent.value};
  width: 100%;
`;

const StDiv20 = styled.div`
  ${Body1};
  width: 100%;
`;

const StDiv21 = styled.div`
  ${Body2};
  color: ${contents.accent.value};
  width: 100%;
`;
