import { useState } from "react";
import { observer } from "mobx-react";
import { MainContact } from "./MainContactView";
import { MSHUTTLE_PATH } from "src/constants/addr";
import { winCall } from "src/func/window";
import { ModalConfirm, ModalStore } from "ms-ui";

export const MainContactView = observer(() => {
  const [modalStore] = useState(new ModalStore());

  return (
    <>
      <MainContact
        onB2BHandler={() => {
          window.location.href = MSHUTTLE_PATH.biz;
        }}
        onDrQuestionHandler={() => {
          modalStore.setOpen(true);
        }}
      />

      {modalStore.open === true && (
        <>
          {/* 통화 alert */}
          <ModalConfirm
            onClick={() => {
              winCall(`02-775-1008`);
              modalStore.setOpen(false);
            }}
            onCancel={() => {
              modalStore.setOpen(false);
            }}
            confirmText={"확인"}
            cancelText={"취소"}
            msg={
              <>
                통화 <br /> 02 775 1008
              </>
            }
            open={modalStore.open}
          />
        </>
      )}
    </>
  );
});
